import { ReactElement, memo } from 'react';
import HeroBlockWrapperContent from './HeroBlockWrapper.props';
import { IHeroBasicBlock } from '../HeroBasicBlock/HeroBasicBlock.props';
import HeroBasicBlock from '../HeroBasicBlock/HeroBasicBlock';
import { AbsoluteImage } from '../ConditionalImage/ConditionalImage';

const HeroBlockWrapper = ({
  content,
  mode,
}: HeroBlockWrapperContent): ReactElement => {
  const basicHeroBlock = {
    title: content.rtfTitle?.html ?? content.title,
    content: content.heroContent,
    backgroundUrl: AbsoluteImage(content.backgroundReference?.url),
    altText: content.alternativeText,
    imagePosition: content.imagePosition,
    isVariant: content.isVariant,
    showBlueFade: content.showBlueFade,
  } as IHeroBasicBlock;

  return <HeroBasicBlock {...basicHeroBlock} />;
};

export default memo(HeroBlockWrapper);
