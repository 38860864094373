import { ReactElement, memo } from 'react';
import EpiserverContentArea from '../../EpiserverContentArea';
import { addEditAttributes } from '../../../../services/Optimizely/utils/episerverAttributes';
import { PortalCampaignPageContent } from './PortalCampaignPage.props';
import { PageWrapper } from '../ContentComponent/ContentComponent.styles';

const PortalCampaignPage = ({
  content,
  mode,
}: PortalCampaignPageContent): ReactElement => {
  return (
    <PageWrapper>
      <div className="contentAlign">
        <main className="Page-container">
          <div {...addEditAttributes('HeroContent')}>
            <EpiserverContentArea content={content.heroContent} mode={mode} />
          </div>
          <div {...addEditAttributes('ContentArea')}>
            <EpiserverContentArea content={content.contentArea} mode={mode} />
          </div>
        </main>
      </div>
    </PageWrapper>
  );
};

export default memo(PortalCampaignPage);
