import styled from 'styled-components';

const VisualCardWrapper = styled.div`
  &.showGreyBg {
    background: #f0f0f0;
    padding: 30px 0;
  }
`;

export default VisualCardWrapper;
