import styled from 'styled-components';
import IndicatorDots from '../IndicatorDots/IndicatorDots';

const CarouselWrapper = styled.div`
  height: 100%;
`;

const DotsWrapper = styled(IndicatorDots)`
  margin-top: 24px;
  text-align: center;
`;

const CarouselCustomPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80px;
  margin: 30px auto 0;

  .swiper-pagination {
    text-align: center;
    width: 60px;

    &,
    span {
      color: ${({ theme }) => theme.colors.neutralCharcoal};
      font-size: 14px;
      line-height: 16px;
      user-select: none;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    cursor: pointer;
    margin-top: 1px;
  }

  .swiper-button-disabled {
    svg path {
      stroke: ${({ theme }) => theme.colors.neutralCharcoal};
    }
  }
`;

export { CarouselWrapper, DotsWrapper, CarouselCustomPagination };
