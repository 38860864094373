/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from 'react';
import { Options, UseObserver } from './types';

const config: Options = {
  root: null,
  rootMargin: '0px',
  threshold: 0,
};

const useObserver = ({
  ref,
  options = {},
  removeAfterInView = false,
}: UseObserver) => {
  const [isElementInView, setElementInView] = useState(false);

  useEffect(() => {
    let observerRefValue: any = null;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setElementInView(entry.isIntersecting);
        if (entry.isIntersecting) {
          setElementInView(entry.isIntersecting);
          if (removeAfterInView && ref?.current)
            observer.unobserve(ref?.current);
        }
      },
      { ...config, ...options }
    );

    if (ref?.current) {
      observer.observe(ref.current);
      observerRefValue = ref.current;
    }

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (observerRefValue) {
        observer.unobserve(observerRefValue);
      }
    };
  }, [isElementInView, options, ref, removeAfterInView]);

  return isElementInView;
};

export default useObserver;
