import { ContextMode } from '@episerver/content-delivery';
import { ReactElement, memo } from 'react';
import { addEditAttributes } from '../../../services/Optimizely/utils/episerverAttributes';
import EpiserverPropertyProps from './EpiserverProperty.props';
import EpiserverPropertyWrapper from './EpiserverProperty.styles';

const EpiserverProperty = ({
  propertyName,
  debug = false,
  mode,
}: EpiserverPropertyProps): ReactElement => {
  return (
    <EpiserverPropertyWrapper>
      {(mode === ContextMode.Edit || debug) && (
        <button {...addEditAttributes(propertyName)} className="button">
          Edit property: {propertyName}
        </button>
      )}
    </EpiserverPropertyWrapper>
  );
};

export default memo(EpiserverProperty);
