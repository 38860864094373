import { FC } from 'react';
import { motion } from 'framer-motion';
import { IBackgroundImage } from './BackgroundImage.props';
import { generateImageUrl } from '../../../services/Optimizely/utils/imageHelper';
import BackgroundImageWrapper from './BackgroundImage.styles';
import { config } from './config';

const BackgroundImage: FC<IBackgroundImage> = ({
  src = '',
  alt = '',
  className = '',
  preload = false,
}: IBackgroundImage) => {
  return (
    <BackgroundImageWrapper>
      <picture>
        {config.reverse().map(({ width, id, media }) => (
          <source
            key={id}
            data-testid={`background-image-${id}`}
            media={media}
            srcSet={generateImageUrl({
              url: src,
              width,
            })}
          />
        ))}
        <motion.img
          src={src}
          alt={alt}
          className={className}
          draggable="false"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ ease: 'easeInOut', duration: 0.3 }}
          data-testid="background-image"
          fetchPriority={preload ? 'high' : 'low'}
        />
      </picture>
    </BackgroundImageWrapper>
  );
};

export default BackgroundImage;
