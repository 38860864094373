import { ICarouselArrows } from './CarouselArrows.props';
import { theme } from '@weirgroup/weir-dls';
import StyledPrimaryIconButton from '../Buttons/StyledPrimaryIconButton';
import { ChangeEvent, FC } from 'react';
import { ThemeProvider } from 'styled-components';
import updateDataLayer from '../../../services/Optimizely/gtm';
import CarouselArrowsWrapper from './CarouselArrows.styles';

const CarouselArrows: FC<ICarouselArrows> = ({
  isStart,
  isEnd,
  activeSlide = 0,
  className,
  onSlideChange,
  onLeftButtonClick,
  onRightButtonClick,
}: ICarouselArrows) => {
  const slideChange = (
    e: ChangeEvent<HTMLButtonElement>,
    slideToChange: number
  ) => {
    onSlideChange?.(slideToChange);
  };

  return (
    <ThemeProvider theme={theme}>
      <CarouselArrowsWrapper className={className}>
        <StyledPrimaryIconButton
          iconName="DXPArrowLeft"
          disabled={isStart}
          title=" "
          onClick={(e: ChangeEvent<HTMLButtonElement>) => {
            slideChange(e, activeSlide - 1);
            onLeftButtonClick?.();
            updateDataLayer({
              event: 'carousel',
            });
          }}
          tabIndex={0}
        />
        <StyledPrimaryIconButton
          iconName="DXPArrowRight"
          disabled={isEnd}
          title=" "
          onClick={(e: ChangeEvent<HTMLButtonElement>) => {
            slideChange(e, activeSlide + 1);
            onRightButtonClick?.();
            updateDataLayer({
              event: 'carousel',
            });
          }}
          tabIndex={0}
        />
      </CarouselArrowsWrapper>
    </ThemeProvider>
  );
};

export default CarouselArrows;
