import ComponentLayout from '../Layout/ComponentLayout';
import { IInlineCarousel } from './InlineCarousel.props';
import { ICarouselOnSlideChange } from '../Carousel/Carousel.props';
import { ThemeProvider } from 'styled-components';
import { FC, useState } from 'react';
import Carousel from '../Carousel/Carousel';
import CarouselArrows from '../CarouselArrows/CarouselArrows';
import useDeviceResize from '../../../services/Optimizely/types/useDeviceResize';
import { theme, Typography } from '@weirgroup/weir-dls';
import InlineCarouselWrapper from './InlineCarousel.styles';
import RichText from '../RichText/RichText';

const InlineCarousel: FC<IInlineCarousel> = ({
  title,
  introduction,
  children,
  breakpoints,
  displayArrows,
  displayPips = !displayArrows,
}: IInlineCarousel) => {
  const [isStart, setIsStart] = useState<boolean>(true);
  const [isEnd, setIsEnd] = useState<boolean>(false);
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(-1);
  const [slideToChange, setSlideToChange] = useState<number>(-1);

  const { isMobile } = useDeviceResize();

  const handleSlideChange = (instance: ICarouselOnSlideChange) => {
    setIsStart(instance.start);
    setIsEnd(instance.end);
    setActiveSlideIndex(instance.active);
  };

  const goToSlide = (slide: number) => {
    setSlideToChange(slide + 1);
  };
  return (
    <ComponentLayout noOverflow>
      <ThemeProvider theme={theme}>
        <InlineCarouselWrapper>
          <div className="vcb-carousel carousel-heading-above-vcb">
            <div className="carousel-heading-above-vcb-text">
              <div>
                {title && (
                  <div className="carousel-heading-above-vcb-title">
                    <Typography variant="DXP-H2">
                      <RichText>{title}</RichText>
                    </Typography>
                  </div>
                )}
                {introduction && (
                  <div className="carousel-heading-above-vcb-intro">
                    <Typography variant="DXP-P1">{introduction}</Typography>
                  </div>
                )}
              </div>
              {displayArrows && (
                <div className="carousel-heading-above-vcb-arrows">
                  <CarouselArrows
                    isStart={isStart}
                    isEnd={isEnd}
                    activeSlide={activeSlideIndex}
                    onSlideChange={goToSlide}
                  />
                </div>
              )}
            </div>
            <Carousel
              loop={isMobile}
              pagination={displayPips}
              centeredSlides={false}
              onSlideChange={handleSlideChange}
              slideToChange={slideToChange}
              breakpoints={breakpoints}
              spaceBetween={32}
            >
              {children}
            </Carousel>
          </div>
        </InlineCarouselWrapper>
      </ThemeProvider>
    </ComponentLayout>
  );
};

export default InlineCarousel;
