import { ContextMode } from '@episerver/content-delivery';
import React, { ReactElement, memo } from 'react';

import EpiserverBlock from '../EpiserverBlock';
import BlockPreviewWrapper from './EpiserverContentArea.styles';
import classNames from '../../../services/Optimizely/utils/classNames';
import displayOptions from '../../../services/Optimizely/utils/displayOptions';

import EpiserverContentAreaProps from './EpiserverContentArea.props';
const EpiserverContentArea = ({
  content,
  mode,
}: EpiserverContentAreaProps): ReactElement => {
  const { getDisplayOption } = displayOptions('block');

  return (
    <BlockPreviewWrapper>
      <section className="container">
        {content?.length > 0 &&
          content.map((block, index) => {
            const displayOption = getDisplayOption(block.displayOption);

            return (
              <div
                className={classNames([
                  'block',
                  !!displayOption ? 'block' : undefined,
                ])}
                key={index}
                data-epi-content-id={
                  mode === ContextMode.Edit ? block.contentLink.guidValue : null
                }
              >
                <EpiserverBlock content={block} mode={mode} />
              </div>
            );
          })}
      </section>
    </BlockPreviewWrapper>
  );
};

export default memo(EpiserverContentArea);
