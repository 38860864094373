export const generateImageUrl = ({
  url = '',
  width = 0,
  height = 0,
}: {
  url: string;
  width?: number;
  height?: number;
}): string => {
  if (!url) return '';

  const widthValue = Math.ceil(width);
  const heightValue = Math.ceil(height);

  // round to nearest 100, minimized amount of images created
  const roundWidth = Math.ceil(widthValue / 100) * 100;
  const roundHeight = Math.ceil(heightValue / 100) * 100;

  const options = {
    ...(roundWidth && { width: roundWidth?.toString() }),
    ...(roundHeight && { height: roundHeight?.toString() }),
  };
  let queryString = `${new URLSearchParams(options)?.toString()}`;
  if (!url.endsWith('.gif')) {
    queryString = `${queryString}&format=webp&quality=90`;
  }
  return `${url}${queryString && `?${queryString}`}`;
};
