import { FC, useState } from 'react';
import { ICarouselOnSlideChange } from '../Carousel/Carousel.props';
import { IVisualCardBlock } from '../VisualCardBlock/VisualCardBlock.props';
import { SwiperSlide } from 'swiper/react';
import InlineCarousel from '../InlineCarousel/InlineCarousel';
import { theme, Typography } from '@weirgroup/weir-dls';
import { ThemeProvider } from 'styled-components';
import Carousel from '../Carousel/Carousel';
import CarouselArrows from '../CarouselArrows/CarouselArrows';
import useDeviceResize from '../../../services/Optimizely/types/useDeviceResize';
import VisualCard from '../VisualCard/VisualCard';
import VisualCardBlockCarouselHeadingAboveWrapper from './VisualCardBlockCarousel.styles';
import breakpoints from './carouselBreakpoints';
import RichText from '../RichText/RichText';

const VisualCardBlockCarouselHeadingAbove: FC<IVisualCardBlock> = ({
  title,
  introduction,
  displayLayout,
  cardSize,
  cards,
  showTealFadeOnCards,
}: IVisualCardBlock) => {
  const [isStart, setIsStart] = useState<boolean>(true);
  const [isEnd, setIsEnd] = useState<boolean>(false);
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(-1);
  const [slideToChange, setSlideToChange] = useState<number>(-1);

  const getHeadingAboveBreakpoints = () => {
    let headingAboveBreakpoints;

    if (cardSize === 'Small') {
      headingAboveBreakpoints = breakpoints.headingAboveSmallCards;
    } else if (cardSize === 'Medium') {
      headingAboveBreakpoints = breakpoints.headingAboveMediumCards;
    } else {
      headingAboveBreakpoints = breakpoints.headingAboveLargeCards;
    }

    return headingAboveBreakpoints;
  };

  const getHeadingLeftBreakpoints = () => {
    let headingLeftBreakpoints;

    if (cardSize === 'Small') {
      headingLeftBreakpoints = breakpoints.headingLeftSmallCards;
    } else if (cardSize === 'Medium') {
      headingLeftBreakpoints = breakpoints.headingLeftMediumCards;
    } else {
      headingLeftBreakpoints = breakpoints.headingLeftLargeCards;
    }

    return headingLeftBreakpoints;
  };

  const carouselBreakpoints =
    displayLayout === 'CarouselHeadingAbove'
      ? getHeadingAboveBreakpoints()
      : displayLayout === 'CarouselHeadingLeft'
        ? getHeadingLeftBreakpoints()
        : {};

  const { isTablet, isDesktop, isMobile, carouselCardsPerViewThreshold } =
    useDeviceResize(carouselBreakpoints, 'slidesPerViewThreshold');

  const displayArrows =
    (isTablet || isDesktop) && cards.length > carouselCardsPerViewThreshold;
  const displayPips = !displayArrows && isMobile;

  const handleSlideChange = (instance: ICarouselOnSlideChange) => {
    setIsStart(instance.start);
    setIsEnd(instance.end);
    setActiveSlideIndex(instance.active);
  };

  const goToSlide = (slide: number) => {
    setSlideToChange(slide + 1);
  };

  const vcbCarouselHeadingAbove = (
    <InlineCarousel
      title={title}
      introduction={introduction}
      breakpoints={getHeadingAboveBreakpoints()}
      displayArrows={displayArrows}
      displayPips={displayPips}
    >
      {cards.map((card) => (
        <SwiperSlide key={card.listingTitle}>
          <div
            className={`carousel-heading-above-vcb-card ${cardSize?.toLowerCase()}`}
          >
            <VisualCard {...card} showTealFadeOnCards={showTealFadeOnCards} />
          </div>
        </SwiperSlide>
      ))}
    </InlineCarousel>
  );

  const vcbCarouselHeadingLeft = (
    <div className="vcb-carousel carousel-heading-left-vcb">
      <div className="carousel-heading-left-vcb-text">
        <div>
          {title && (
            <div className="carousel-heading-left-vcb-title">
              <Typography variant="DXP-H2">
                <RichText>{title}</RichText>
              </Typography>
            </div>
          )}
          {introduction && (
            <div className="carousel-heading-left-vcb-intro">
              <Typography variant="DXP-P1">{introduction}</Typography>
            </div>
          )}
        </div>
        {displayArrows && (
          <div className="carousel-heading-left-vcb-arrows">
            <CarouselArrows
              isStart={isStart}
              isEnd={isEnd}
              activeSlide={activeSlideIndex}
              onSlideChange={goToSlide}
            />
          </div>
        )}
      </div>
      <div className="carousel-heading-left-vcb-carousel">
        <Carousel
          loop={false}
          pagination={displayPips}
          centeredSlides={false}
          onSlideChange={handleSlideChange}
          slideToChange={slideToChange}
          breakpoints={getHeadingLeftBreakpoints()}
        >
          {cards.map((card) => (
            <SwiperSlide key={card.listingTitle}>
              <div
                className={`carousel-heading-left-vcb-card ${cardSize?.toLowerCase()}`}
              >
                <VisualCard {...card} />
              </div>
            </SwiperSlide>
          ))}
        </Carousel>
      </div>
    </div>
  );

  return (
    <ThemeProvider theme={theme}>
      <VisualCardBlockCarouselHeadingAboveWrapper>
        {displayLayout === 'CarouselHeadingAbove' && vcbCarouselHeadingAbove}
        {displayLayout === 'CarouselHeadingLeft' && vcbCarouselHeadingLeft}
      </VisualCardBlockCarouselHeadingAboveWrapper>
    </ThemeProvider>
  );
};

export default VisualCardBlockCarouselHeadingAbove;
