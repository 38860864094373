import styled from 'styled-components';

const CarouselArrowsWrapper = styled.div`
  button {
    border-radius: 0;
    height: 68px;
    width: 68px;

    svg {
      margin-left: 6px;
      vertical-align: middle;
    }
  }
  &.full-width-arrows {
    position: absolute;
    width: 84%;
    display: flex;
    left: 8%;
    justify-content: space-between;
    top: calc(50% - 34px);
    z-index: 9;
  }
`;

export default CarouselArrowsWrapper;
