import { ReactElement, memo } from 'react';
import RichTextVisualCardBlockWrapperProps from './RichTextVisualCardBlockWrapper.props';
import VisualCardBlock from '../VisualCardBlock/VisualCardBlock';

const RichTextVisualCardBlockWrapper = ({
  content,
  mode,
}: RichTextVisualCardBlockWrapperProps): ReactElement => {
  const expandedContent = content.cards.map((c) => ({
    listingImage:
      c.contentLink.expanded.listingImage?.url +
      '?width=700&format=webp&quality=90',
    listingTitle: c.contentLink.expanded.listingTitle,
    listingIntro: c.contentLink.expanded.listingIntro,
    targetUrl: c.contentLink.expanded.targetUrl ?? c.contentLink.expanded.url,
    showTealFadeOnCards: c.contentLink.expanded.showTealFadeOnCards ?? false,
  }));

  content.cards = expandedContent;
  content.title = content.rtfTitle?.html;

  return <VisualCardBlock {...content} />;
};

export default memo(RichTextVisualCardBlockWrapper);
