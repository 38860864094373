import styled from 'styled-components';

const EpiserverGenericBlockWrapper = styled.div`
  .container {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: var(--space-l);
  }

  .text {
    @include typography.text-l;
  }
`;

export default EpiserverGenericBlockWrapper;
