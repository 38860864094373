import styled from 'styled-components';

const RichTextWrapper = styled.div`
  * {
    font-family: Roboto, sans-serif;
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${({ theme }) => theme.colors.onyx};
    font-size: 24px;
    font-weight: 400;
    line-height: 35px;
    margin-bottom: 6px;
  }

  h2:not(.left-heading) {
    font-family: Roboto, sans-serif;
    font-size: 28px;
    font-weight: 300;
    line-height: 33px;
    letter-spacing: 0px;

    @media (min-width: 1024px) {
      font-size: 38px;
      font-weight: 300;
      line-height: 50px;
    }
  }
  h3 {
    font-family: Roboto, sans-serif;
    font-size: 28px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0px;

    @media (min-width: 1024px) {
      font-size: 28px;
      line-height: 35px;
    }
  }
  h4 {
    font-family: Roboto, sans-serif;
    letter-spacing: 0px;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
  }
  h5 {
    font-family: Roboto, sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0px;

    @media (min-width: 1024px) {
      line-height: 35px;
    }
  }

  p,
  ul,
  ol {
    color: ${({ theme }) => theme.colors.neutralCharcoal};
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 20px;
  }

  a {
    color: ${({ theme }) => theme.colors.weirBlueHighlight};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol {
    &: last-child {
      margin-bottom: 0;
    }
  }

  @media (min-width: 1024px) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 8px;
    }

    p,
    ul,
    ol {
      font-size: 16px;
      line-height: 27px;
      margin-bottom: 32px;
    }
  }
`;

export default RichTextWrapper;
