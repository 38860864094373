import { ReactElement, memo } from 'react';
import EpiserverContentArea from '../../EpiserverContentArea';
import { HubStartPageContent } from './HubStartPage.props';
import { addEditAttributes } from '../../../../services/Optimizely/utils/episerverAttributes';

const HubStartPage = ({ content, mode }: HubStartPageContent): ReactElement => {
  return (
    <>
      <div>
        <h1 className={content.title} {...addEditAttributes('Title')}>
          {content.title}
        </h1>
        <p className="preamble" {...addEditAttributes('Preamble')}>
          {content.preamble}
        </p>
      </div>

      <main className="Page-container">
        <div {...addEditAttributes('ContentArea')}>
          <EpiserverContentArea content={content.contentArea} mode={mode} />
        </div>
      </main>
    </>
  );
};

export default memo(HubStartPage);
