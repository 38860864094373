import styled from 'styled-components';

const EpiserverPropertyWrapper = styled.div`
  .button {
    background: var(--color-background);
    border: 2px solid var(--color-text);
    color: var(--color-text);
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    margin-block: 0.5rem;
    padding: 1rem 0.5rem;
    text-transform: uppercase;

    &:hover {
      background-color: var(--color-text);
      color: var(--color-background);
    }
  }
`;

export default EpiserverPropertyWrapper;
