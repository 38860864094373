import RichText from '../RichText/RichText';
import ComponentLayout from '../Layout/ComponentLayout';
import { ITextBlock } from './TextBlock.props';
import { theme } from '@weirgroup/weir-dls';
import { FC } from 'react';
import { ThemeProvider } from 'styled-components';
import {
  TextBlockComponentWrapper,
  TextBlockWrapper,
  GreyBackgroundWrapper,
} from './TextBlock.styles';

const TextBlock: FC<ITextBlock> = ({
  title,
  text,
  textLayout,
  isVariant,
  enableAlternativeBackground,
}: ITextBlock) => {
  const defaultTextBlock = !title && text && (
    <RichText className="rich-text-headings">{text}</RichText>
  );

  const headingLeftTextBlock = (
    <TextBlockComponentWrapper className={`${isVariant && 'brand secondary'}`}>
      <div className="heading-left">
        {title && <h2 className="left-heading title-heading">{title}</h2>}
        {text && (
          <div className="rich-text rich-text-headings">
            <RichText>{text}</RichText>
          </div>
        )}
      </div>
    </TextBlockComponentWrapper>
  );

  const twoColumnsTextBlock = (
    <TextBlockComponentWrapper className={`${isVariant && 'brand secondary'}`}>
      <div className="two-columns">
        {title && <h2 className="title-heading">{title}</h2>}
        {text && (
          <div className="columns rich-text-headings">
            <RichText>{text}</RichText>
          </div>
        )}
      </div>
    </TextBlockComponentWrapper>
  );

  if (!text) {
    return null;
  }

  return (
    <GreyBackgroundWrapper
      className={`${enableAlternativeBackground && 'showGreyBg'}`}
    >
      <TextBlockComponentWrapper
        className={`${isVariant && 'brand secondary'}`}
      >
        <ThemeProvider theme={theme}>
          <ComponentLayout>
            <TextBlockWrapper>
              {/* TODO: Look at dynamically loading components in  */}
              {textLayout === 'Default' && defaultTextBlock}
              {(textLayout === 'HeadingLeft' || textLayout === 'Product') &&
                headingLeftTextBlock}
              {textLayout === 'TwoColumns' && twoColumnsTextBlock}
            </TextBlockWrapper>
          </ComponentLayout>
        </ThemeProvider>
      </TextBlockComponentWrapper>
    </GreyBackgroundWrapper>
  );
};

export default TextBlock;
