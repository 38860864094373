import styled from 'styled-components';

export const PageWrapper = styled.div`
  .contentAlign {
    margin: 0px 24px 64px 24px;
    max-width: 1550px;
  }
  .siteInfo {
    padding-top: 5px;
  }
  .pagebody .preamble {
    max-width: 80%;
  }
`;

export const TitleWrapper = styled.div`
  padding-left: 20px;
  padding-top: 40px;
`;

export const TextBlockWrapper = styled.div`
  padding-left: 20px;
  padding-right: 40px;
  padding-top: 20px;
`;
