import { IRichText } from './RichText.props';
import { theme } from '@weirgroup/weir-dls';
import { FC, memo } from 'react';
import { ThemeProvider } from 'styled-components';
import RichTextWrapper from './RichText.styles';

const RichText: FC<IRichText> = ({ children, className }: IRichText) => {
  if (!children) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <RichTextWrapper>
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: children as string }}
          {...(className && { className })}
        />
      </RichTextWrapper>
    </ThemeProvider>
  );
};

export default memo(RichText);
