import { ReactElement, Suspense, memo, lazy } from 'react';
import MyAccessBlockProps from './MyAccessBlock.props';
import Loader from '../../Loader/Loader';
import { history } from '../../../services/_helpers/history';

const MyAccessPage = lazy(
  () =>
    import(
      '../../../modules/MyAccess/MyAccess/containers/MyAccessPage.container'
    )
);

const MyAccessBlock = ({ content, mode }: MyAccessBlockProps): ReactElement => {
  return (
    <>
      <Suspense fallback={<Loader />}>
        <MyAccessPage history={history} content={content} />
      </Suspense>
    </>
  );
};

export default memo(MyAccessBlock);
