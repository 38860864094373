import styled from 'styled-components';

const HeroWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  width: 100%;

  .side-image {
    @media (min-width: 1024px) {
      display: none;
    }
  }

  .full-width-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
  }

  &.full-width {
    overflow: hidden;
    position: relative;

    &.with-image:after {
      content: '';
      height: 100%;
      inset: 0;
      position: absolute;
      width: 100%;
      z-index: 1;
      background: rgba(0, 0, 0, 0.4);
    }
    &.blue-fade:after {
      background: linear-gradient(
        to right,
        rgba(1, 47, 94, 0.85) 0.77%,
        rgba(0, 94, 184, 0.77) 49.92%,
        rgba(0, 94, 184, 0) 74.58%
      );
    }
  }

  h1 {
    font-weight: 900;
    letter-spacing: 2px;
    p {
      font-weight: 900;
      letter-spacing: 2px;
    }
  }

  &.blue-fade.full-width-position h1 span {
    color: #66cad3;
  }
`;

const HeroBasicWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column-reverse;
  font-family: Roboto, sans-serif;
  height: 520px;
  position: relative;
  z-index: 2;

  &.reduced-hero {
    height: 290px;
    @media (min-width: 768px) {
      height: 290px;
    }
  }

  h1 {
    margin-bottom: 0px;
    font-weight: 900;
    letter-spacing: 2px;
    p {
      font-weight: 900;
      letter-spacing: 2px;
    }
  }

  .content {
    text-align: left;

    p {
      margin-bottom: 0;
    }

    &.no-image {
      padding-bottom: 22px;
      padding-right: 22px;
    }
  }

  img {
    object-fit: cover;
    object-position: center;
    position: absolute;
  }

  &.image-left {
    img {
      aspect-ratio: 384 / 556;
      height: 556px;
      left: 0;
      max-width: 384px;
      position: absolute;
      top: 0;
    }

    .content {
      padding: ${({ theme }) => theme.colWidthDesktop};
      padding-left: 384px;
      padding-bottom: 0px;

      @media (min-width: 1025px) {
        padding-bottom: 50px;
      }
    }

    .breadcrumbs {
      left: calc(384px + 3rem);
      width: calc(100% - 384px + 6rem);

      @media (min-width: 1280px) {
        left: calc(384px + 4rem);
        width: calc(80% - 384px + 8rem);
      }
    }
  }

  &.image-right {
    img {
      aspect-ratio: 600 / 550;
      height: 550px;
      max-width: 600px;
      position: absolute;
      right: 0;
      top: 0;
    }

    .content {
      padding-right: 648px;
      padding-bottom: 50px;
      margin-left: 64px;
    }

    .breadcrumbs {
      left: calc(3rem + 64px);
      width: calc(100% - 664px + 6rem);

      @media (min-width: 1280px) {
        left: calc(4rem + 64px);
        width: calc(100% - 664px + 8rem);
      }
    }
  }

  &.no-image {
    .breadcrumbs {
      width: calc(100% - 40px);

      @media (min-width: 1024px) {
        width: calc(100% - 112px);
      }
    }
  }

  .h-line {
    position: absolute;
    top: ${({ theme }) => theme.colWidthDesktop};
    right: ${({ theme }) => theme.colWidthDesktop};
    height: 1px;
    background-color: ${({ theme }) => theme.colors.white};
    opacity: 20%;
    width: calc(100% - 64px);

    &.image-left {
      right: ${({ theme }) => theme.colWidthDesktop};
      width: calc(100% - 448px);
    }
    &.image-right {
      right: 0;
    }
  }

  .v-line {
    position: absolute;
    top: 0;
    right: ${({ theme }) => theme.colWidthDesktop};
    width: 1px;
    background-color: ${({ theme }) => theme.colors.white};
    opacity: 20%;
    height: 100%;

    &.image-right {
      left: ${({ theme }) => theme.colWidthDesktop};
    }
  }

  .content {
    z-index: 2;
    padding-bottom: 64px;
  }

  @media (max-width: 1024px) {
    display: block;
    overflow: hidden;

    .content {
      &:not(.no-image) {
        bottom: 64px;
      }

      &.no-image {
        bottom: 0px;
      }

      max-height: 443px;
      position: absolute;
      width: 100%;
    }

    img {
      height: 100%;
    }

    .h-line {
      display: none;
    }

    .v-line {
      display: none;
    }
  }
`;

const BreadcrumbWrapper = styled.div`
  position: absolute;
  top: 22px;
  z-index: 1;
  width: 90%;
  left: 5%;
`;

const TitleWrapper = styled.h1`
  width: 100%;
  margin-top: 50px;
  margin-bottom: 0;
  font-size: clamp(36px, 4vw, 62px);
  color: white;
  line-height: 1.3em;
  font-family: Roboto, sans-serif;
  font-weight: 600;
  display: flex;

  div > div > p {
    font-size: clamp(36px, 4vw, 62px);
    color: white;
    line-height: 1.3em;
    font-family: Roboto, sans-serif;
    font-weight: 600;
    display: flex;
  }

  &.image-left {
    max-height: 200px;
  }
`;

const ContentWrapper = styled.div`
  p {
    font-size: 1rem;
    line-height: 1.375rem;
  }

  @media (min-width: 1024px) {
    p {
      font-size: 1.125rem;
      line-height: 1.75rem;
    }
  }

  p {
    color: ${({ theme }) => theme.colors.dust};
  }
`;

export {
  BreadcrumbWrapper,
  ContentWrapper,
  HeroBasicWrapper,
  TitleWrapper,
  HeroWrapper,
};
