import { IImageComponent } from './ImageComponent.props';
import { FC, useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import useObserver from '../../../services/Optimizely/hooks/useObserver';
import { generateImageUrl } from '../../../services/Optimizely/utils/imageHelper';
import { ImageWrapper } from './Image.styles';

const ImageComponent: FC<IImageComponent> = ({
  src = '',
  alt = '',
  title = '',
  className = '',
  noLazy = false,
  useHeight = false,
  noAnimation = false,
}) => {
  const [{ width, height }, setDimensions] = useState<{
    width: number;
    height: number;
  }>({
    width: 0,
    height: 0,
  });
  const containerRef = useRef<HTMLDivElement>(null);
  const isElementInView = useObserver({
    ref: containerRef,
    removeAfterInView: true,
  });

  const dimensions = useHeight
    ? { height: height * 1.5 }
    : { width: width * 1.5 };
  const url = generateImageUrl({ url: src, ...dimensions });
  const animation = noAnimation
    ? {}
    : {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        transition: { ease: 'easeInOut', duration: 0.3 },
      };

  useEffect(() => {
    if (!containerRef?.current) return;

    setDimensions({
      width: containerRef?.current?.offsetWidth ?? 0,
      height: containerRef?.current?.offsetHeight ?? 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef?.current]);

  return (
    <ImageWrapper
      ref={containerRef}
      className="image-container"
      data-testid="image-container"
    >
      {((isElementInView && url && width) || noLazy) && (
        <motion.img
          src={url}
          alt={alt}
          title={title}
          className={className}
          draggable="false"
          {...animation}
          data-testid="image"
          loading="lazy"
        />
      )}
    </ImageWrapper>
  );
};

export default ImageComponent;
