import styled from 'styled-components';

const ImageWrapper = styled.div`
  width: 100%;
  height: auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 862/600;
  }
`;

export default ImageWrapper;
