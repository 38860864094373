import React, { ReactElement, memo } from 'react';

import classNames from '../../../services/Optimizely/utils/classNames';
import { addEditAttributes } from '../../../services/Optimizely/utils/episerverAttributes';

import ConditionalImage from '../ConditionalImage';
import EpiserverProperty from '../EpiserverProperty';
import ContentBlockProps from './ContentBlock.props';

const ContentBlock = ({ content, mode }: ContentBlockProps): ReactElement => {
  return (
    <div
      className={classNames([
        'container',
        content.imageAlignment === 'Right' ? 'container--flip' : undefined,
        content.image ? 'container--two-col' : undefined,
      ])}
    >
      {content.image && (
        <ConditionalImage
          src={content.image}
          alt={content.title}
          {...addEditAttributes('Image')}
        />
      )}
      <div className={classNames(['content'])}>
        <h2 {...addEditAttributes('Title')}>{content.title}</h2>
        <div
          {...addEditAttributes('Content')}
          dangerouslySetInnerHTML={{ __html: content.content || '' }}
        />
        {!content.image && (
          <EpiserverProperty propertyName="image" mode={mode} />
        )}
      </div>
    </div>
  );
};

export default memo(ContentBlock);
