// when either of slidesPerView or slidesPerViewThreshold are changed, a check of the other one must be performed
const headingLeftSmallCards = {
  0: {
    slidesPerView: 1,
    slidesPerViewThreshold: 1,
  },
  769: {
    slidesPerView: 1.95,
    slidesPerViewThreshold: 2,
  },
  1025: {
    slidesPerView: 1.99,
    slidesPerViewThreshold: 2,
  },
  1281: {
    slidesPerView: 2.99,
    slidesPerViewThreshold: 3,
  },
  1540: {
    slidesPerView: 4,
    slidesPerViewThreshold: 4,
  },
};

const headingLeftMediumCards = {
  0: {
    slidesPerView: 1,
    slidesPerViewThreshold: 1,
  },
  769: {
    slidesPerView: 1,
    slidesPerViewThreshold: 1,
  },
  1025: {
    slidesPerView: 2,
    slidesPerViewThreshold: 2,
  },
  1140: {
    slidesPerView: 2,
    slidesPerViewThreshold: 2,
  },
  1281: {
    slidesPerView: 1.99,
    slidesPerViewThreshold: 2,
  },
  1540: {
    slidesPerView: 3,
    slidesPerViewThreshold: 3,
  },
};

const headingLeftLargeCards = {
  0: {
    slidesPerView: 1,
    slidesPerViewThreshold: 1,
  },
  769: {
    slidesPerView: 1,
    slidesPerViewThreshold: 1,
  },
  1025: {
    slidesPerView: 1,
    slidesPerViewThreshold: 1,
  },
  1140: {
    slidesPerView: 1,
    slidesPerViewThreshold: 1,
  },
  1281: {
    slidesPerView: 0.99,
    slidesPerViewThreshold: 1,
  },
  1540: {
    slidesPerView: 2,
    slidesPerViewThreshold: 2,
  },
};

const headingAboveSmallCards = {
  0: {
    slidesPerView: 1,
    slidesPerViewThreshold: 1,
  },
  768: {
    slidesPerView: 1.8,
    slidesPerViewThreshold: 2,
  },
  1025: {
    slidesPerView: 2.7,
    slidesPerViewThreshold: 3,
  },
  1281: {
    slidesPerView: 3.6,
    slidesPerViewThreshold: 4,
  },
};

const headingAboveMediumCards = {
  0: {
    slidesPerView: 1,
    slidesPerViewThreshold: 1,
  },
  768: {
    slidesPerView: 1.8,
    slidesPerViewThreshold: 2,
  },
  1025: {
    slidesPerView: 1.8,
    slidesPerViewThreshold: 2,
  },
  1281: {
    slidesPerView: 2.7,
    slidesPerViewThreshold: 3,
  },
  1560: {
    slidesPerView: 2.9,
    slidesPerViewThreshold: 3,
  },
};

const headingAboveLargeCards = {
  0: {
    slidesPerView: 1,
    slidesPerViewThreshold: 1,
  },
  768: {
    slidesPerView: 0.9,
    slidesPerViewThreshold: 1,
  },
  1025: {
    slidesPerView: 1.8,
    slidesPerViewThreshold: 2,
  },
  1441: {
    slidesPerView: 1.85,
    slidesPerViewThreshold: 2,
  },
  1560: {
    slidesPerView: 1.95,
    slidesPerViewThreshold: 2,
  },
};

const headings = {
  headingLeftSmallCards,
  headingLeftMediumCards,
  headingLeftLargeCards,
  headingAboveSmallCards,
  headingAboveMediumCards,
  headingAboveLargeCards,
};

export default headings;
