import { ReactElement, Suspense, memo, lazy } from 'react';
import { addEditAttributes } from '../../../services/Optimizely/utils/episerverAttributes';
import MyActivityBlockProps from './MyActivityBlock.props';
import Loader from '../../Loader/Loader';
import { TitleWrapper } from '../pages/ContentComponent/ContentComponent.styles';

const MyActivityPage = lazy(
  () =>
    import('../../../modules/MyActivity/containers/MyActivityPage.container')
);

const MyActivityBlock = ({
  content,
  mode,
}: MyActivityBlockProps): ReactElement => {
  return (
    <>
      {content.title && (
        <TitleWrapper {...addEditAttributes('Title')}>
          <h2>{content.title}</h2>
        </TitleWrapper>
      )}
      <Suspense fallback={<Loader />}>
        <MyActivityPage content={content} />
      </Suspense>
    </>
  );
};

export default memo(MyActivityBlock);
