import styled from 'styled-components';

const InlineCarouselWrapper = styled.div`
  .vcb-carousel {
    width: 100%;
    @media (min-width: 1560px) {
      padding: 0;
    }
    .carousel-heading-above-vcb-card {
      box-sizing: content-box;
      margin-right: 32px;
    }
    .swiper {
      overflow: visible;
      margin-right: inherit;

      .swiper-wrapper .swiper-slide {
        @media (min-width: 768px) and (max-width: 1280px) {
          width: 50% !important;
        }
        @media (max-width: 767px) {
          width: 100% !important;
          margin-right: 16px !important;
        }
      }
    }

    @media (max-width: 768px) {
      .carousel-heading-above-vcb-card {
        margin-right: 0;
      }
    }
  }

  .carousel-heading-above-vcb {
    .carousel-heading-above-vcb-text {
      margin-bottom: 32px;

      .carousel-heading-above-vcb-title {
        p {
          color: ${({ theme }) => theme.colors.neutralBlack};
          font-size: 28px;
          font-weight: 300;
          line-height: 33px;
          margin-bottom: 16px;
          text-align: center;
        }
      }

      .carousel-heading-above-vcb-intro {
        p {
          color: ${({ theme }) => theme.colors.neutralCharcoal};
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          text-align: center;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .carousel-heading-above-vcb {
      .carousel-heading-above-vcb-text {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 50px;

        .carousel-heading-above-vcb-title {
          p {
            text-align: left;
            font-size: 38px;
            line-height: 50px;
            max-width: 750px;
          }
        }

        .carousel-heading-above-vcb-intro {
          p {
            text-align: left;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            max-width: 750px;
          }
        }

        .carousel-heading-above-vcb-arrows {
          flex-shrink: 0;
          width: 136px;
        }
      }
    }
  }
`;

export default InlineCarouselWrapper;
