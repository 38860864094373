import styled from 'styled-components';

const VisualCardBlockCarouselWrapper = styled.div`
  .vcb-carousel {
    width: 100%;
    @media (min-width: 768px) {
      &.carousel-heading-above-vcb {
        padding-left: 0;
      }
      &.carousel-heading-left-vcb {
        padding: 0 2rem;
      }
    }

    @media (min-width: 1024px) {
      &.carousel-heading-left-vcb {
        padding: 0 3rem;
      }
    }

    @media (min-width: 1280px) {
      &.carousel-heading-left-vcb {
        padding: 0 4rem;
      }
    }

    @media (min-width: 1560px) {
      padding-right: 0;
    }

    .carousel-heading-left-vcb-card {
      box-sizing: content-box;

      @media (min-width: 769px) {
        &.small {
          margin-right: 20px;
        }
      }

      @media (min-width: 1024px) {
        margin-right: 20px;
      }
    }

    @media (max-width: 768px) {
      .carousel-heading-left-vcb-card {
        margin-right: 0;
      }
    }
  }

  .carousel-heading-left-vcb {
    .carousel-heading-left-vcb-text {
      margin-bottom: 32px;

      .carousel-heading-left-vcb-title {
        p {
          color: ${({ theme }) => theme.colors.neutralBlack};
          font-size: 28px;
          font-weight: 300;
          line-height: 33px;
          margin-bottom: 16px;
          text-align: center;
        }
      }

      .carousel-heading-left-vcb-intro {
        p {
          color: ${({ theme }) => theme.colors.neutralCharcoal};
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          text-align: center;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .carousel-heading-left-vcb {
      display: grid;
      grid-template-columns: repeat(12, [col-start] 8.33%);

      .carousel-heading-left-vcb-text {
        grid-column: 1 / span 3;
        margin-right: 32px;
        margin-bottom: 0;

        .carousel-heading-left-vcb-title {
          p {
            text-align: left;
            font-size: 38px;
            line-height: 50px;
            max-width: 750px;
          }
        }

        .carousel-heading-left-vcb-intro {
          p {
            text-align: left;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            max-width: 750px;
          }
        }

        .carousel-heading-left-vcb-arrows {
          padding-top: 32px;
        }
      }

      .carousel-heading-left-vcb-carousel {
        grid-column: 4 / span 9;
        @media (min-width: 768px) {
          &:after {
            content: '';
            position: absolute;
            height: 100%;
            width: 60px;
            right: 30px;
            top: 0;
            z-index: 1;
            background: rgb(255, 255, 255);
            background: linear-gradient(
              90deg,
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 1) 70%
            );
          }
        }
        @media (min-width: 1560px) {
          &:after {
            right: -2px;
          }
        }
      }
    }
  }
`;

export default VisualCardBlockCarouselWrapper;
