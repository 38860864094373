import styled from 'styled-components';

const ImageWrapper = styled.div`
  .default {
    overflow: hidden;
    padding-bottom: calc(100% * (9 / 16));
    object-fit: contain;
    position: relative;
    width: 100%;
  }

  .border {
    border-radius: 0px 20px;
  }

  .caption {
    margin-top: 16px;

    p {
      color: ${({ theme }) => theme.colors.neutralCharcoal};
    }
  }

  img {
    height: 0;
    inset: 0;
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    width: 0;
  }
`;

export { ImageWrapper };
