import ComponentLayout from '../Layout/ComponentLayout';
import { IImage } from './Image.props';
import { theme, Typography } from '@weirgroup/weir-dls';
import { FC } from 'react';
import { ThemeProvider } from 'styled-components';
import ImageComponent from './ImageComponent';
import { ImageWrapper } from './Image.styles';

const Image: FC<IImage> = ({
  imageUrl = '',
  altText = '',
  title,
  narrowWidth,
  caption,
}: IImage) => (
  <ComponentLayout>
    <ThemeProvider theme={theme}>
      <ImageWrapper>
        <div className={`default ${narrowWidth && 'narrow-width border'}`}>
          {imageUrl && (
            <ImageComponent src={imageUrl} alt={altText} title={title} />
          )}
        </div>
        {caption && (
          <div className="caption">
            <Typography variant="DXP-P1" textAlign="center">
              {caption}
            </Typography>
          </div>
        )}
      </ImageWrapper>
    </ThemeProvider>
  </ComponentLayout>
);

export default Image;
