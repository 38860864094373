import { FC } from 'react';
import { IVisualCardBlock } from './VisualCardBlock.props';
import VisualCardBlockInline from '../VisualCardBlockInline/VisualCardBlockInline';
import VisualCardBlockCarousel from '../VisualCardBlockCarousel/VisualCardBlockCarousel';
import ComponentLayout from '../Layout/ComponentLayout';
import VisualCardWrapper from './VisualCardBlock.styles';

const VisualCardBlock: FC<IVisualCardBlock> = ({
  title,
  introduction,
  displayLayout,
  cardSize,
  cards,
  showTealFadeOnCards,
  enableAlternativeBackground,
}: IVisualCardBlock) => {
  return displayLayout === 'Inline' ? (
    <VisualCardWrapper
      className={`${enableAlternativeBackground && 'showGreyBg'}`}
    >
      <ComponentLayout>
        <VisualCardBlockInline
          cards={cards}
          title={title}
          cardSize={cardSize}
          introduction={introduction}
          showTealFadeOnCards={showTealFadeOnCards}
          enableAlternativeBackground={enableAlternativeBackground}
        />
      </ComponentLayout>
    </VisualCardWrapper>
  ) : (
    <VisualCardWrapper
      className={`${enableAlternativeBackground && 'showGreyBg'}`}
    >
      <ComponentLayout noPadding noOverflow>
        <VisualCardBlockCarousel
          cards={cards}
          title={title}
          cardSize={cardSize}
          introduction={introduction}
          displayLayout={displayLayout}
          showTealFadeOnCards={showTealFadeOnCards}
          enableAlternativeBackground={enableAlternativeBackground}
        />
      </ComponentLayout>
    </VisualCardWrapper>
  );
};

export default VisualCardBlock;
