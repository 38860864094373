import { ReactElement, memo } from 'react';
import EpiserverContentArea from '../../EpiserverContentArea';
import { ApplicationPageContent } from './ApplicationPage.props';
import { addEditAttributes } from '../../../../services/Optimizely/utils/episerverAttributes';

import { PageWrapper } from '../ContentComponent/ContentComponent.styles';

const ApplicationPage = ({
  content,
  mode,
}: ApplicationPageContent): ReactElement => {
  return (
    <PageWrapper>
      <div className="contentAlign">
        <h1 className={content.title} {...addEditAttributes('Title')}>
          {content.title}
        </h1>
        <p className="preamble" {...addEditAttributes('Preamble')}>
          {content.preamble}
        </p>
      </div>

      <main className="Page-container">
        <div {...addEditAttributes('ContentArea')}>
          <EpiserverContentArea content={content.contentArea} mode={mode} />
        </div>
      </main>
    </PageWrapper>
  );
};

export default memo(ApplicationPage);
