import styled from 'styled-components';

const FeatureComponentWrapper = styled.div`
  display: inline-block;
  width: 100%;
  &.showGreyBg {
    background: #f0f0f0;
  }
`;

const FeatureBlockWrapper = styled.div`
  position: relative;
  .swiper-slide {
    height: auto;
    display: flex;
  }

  .carousel-buttons {
    position: absolute;
    right: 32px;
    bottom: -20px;
    z-index: 10;
  }

  .decorative-line {
    width: 100%;
    left: 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.stone};
    position: absolute;
    height: 1px;
    bottom: 0px;
    display: none;
    z-index: -1;
    opacity: 0.5;

    @media (min-width: 1024px) {
      display: block;
    }
  }
`;

export { FeatureBlockWrapper, FeatureComponentWrapper };
