import React, { ReactElement, memo } from 'react';

import EpiserverGenericBlockProps from './EpiserverGenericBlock.props';
import EpiserverGenericBlockWrapper from './EpiserverGenericBlock.styles';

const EpiserverGenericBlock = ({
  content,
}: EpiserverGenericBlockProps): ReactElement => {
  return (
    <EpiserverGenericBlockWrapper>
      <div className="container">
        <p className="text">
          {`Could not load ${content.name || 'the'} react component.`}
        </p>
      </div>
    </EpiserverGenericBlockWrapper>
  );
};

export default memo(EpiserverGenericBlock);
