export const config = [
  {
    id: 'mobile',
    media: 'screen and (max-width: 425px)',
    width: 425,
  },
  {
    id: 'mobile-L',
    media: 'screen and (min-width: 425px) and (max-width: 768px)',
    width: 768,
  },
  {
    id: 'tablet',
    media: 'screen and (min-width: 768px) and (max-width: 1023px)',
    width: 1024,
  },
  {
    id: 'desktop',
    media: 'screen and (min-width: 1024px)',
    width: 1440,
  },
];
