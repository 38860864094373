import styled from 'styled-components';

const Layout = styled('section')<{
  $noMargin: boolean;
  $noPadding: boolean;
  $fullHeight: boolean;
  $noOverflow: boolean;
  $backgroundColor?: string;
}>`
  margin: ${({ $noMargin }) => ($noMargin ? '0 auto' : '3rem auto')};
  /* max-width: 1440px; */
  padding: ${({ $noPadding }) => ($noPadding ? '0' : '0 1.25rem')};
  width: 100%;
  background-color: ${({ $backgroundColor }) => $backgroundColor || ''};
  position: relative;
  height: ${({ $fullHeight }) => ($fullHeight ? '100%' : '')};
  overflow: ${({ $noOverflow }) => ($noOverflow ? 'hidden' : '')};

  @media (min-width: 768px) {
    margin: ${({ $noMargin }) => ($noMargin ? '0 auto' : '4rem auto')};
    padding: ${({ $noPadding }) => ($noPadding ? '0' : '0 2rem')};
  }

  @media (min-width: 1024px) {
    margin: ${({ $noMargin }) => ($noMargin ? '0 auto' : '4.5rem auto')};
    padding: ${({ $noPadding }) => ($noPadding ? '0' : '0 3rem')};
  }

  @media (min-width: 1280px) {
    margin: ${({ $noMargin }) => ($noMargin ? '0 auto' : '5rem auto')};
    padding: ${({ $noPadding }) => ($noPadding ? '0' : '0 4rem')};
  }
`;

export default Layout;
