import { ReactElement, memo } from 'react';
import EpiserverContentArea from '../../EpiserverContentArea';
import { addEditAttributes } from '../../../../services/Optimizely/utils/episerverAttributes';
import { PortalContentPageContent } from './PortalContentPage.props';
import TextAreaBlock from '../../TextArea/TextAreaBlock';
import TextAreaBlockProps from '../../TextArea/TextAreaBlock.props';
import {
  PageWrapper,
  TitleWrapper,
  TextBlockWrapper,
} from '../ContentComponent/ContentComponent.styles';

const PortalContentPage = ({
  content,
  mode,
}: PortalContentPageContent): ReactElement => {
  const mainBody = {
    content: {
      text: content.mainBody,
    },
    attribute: 'MainBody',
  } as TextAreaBlockProps;

  return (
    <PageWrapper>
      <div className="contentAlign">
        {content.title && (
          <TitleWrapper>
            <h1 className={content.title} {...addEditAttributes('Title')}>
              {content.title}
            </h1>
            <p className="preamble" {...addEditAttributes('Preamble')}>
              {content.preamble}
            </p>
          </TitleWrapper>
        )}
        <TextBlockWrapper>
          <TextAreaBlock {...mainBody} />
        </TextBlockWrapper>
        <main className="Page-container">
          <div {...addEditAttributes('ContentArea')}>
            <EpiserverContentArea content={content.contentArea} mode={mode} />
          </div>
        </main>
      </div>
    </PageWrapper>
  );
};

export default memo(PortalContentPage);
