import { ReactElement, memo } from 'react';

import BlockComponentSelector from '../BlockComponentSelector';
import EpiserverBlockProps from './EpiserverBlockProps';

const EpiserverBlock = ({
  content,
  mode,
}: EpiserverBlockProps): ReactElement => (
  <BlockComponentSelector
    content={content.contentLink.expanded}
    mode={mode}
  ></BlockComponentSelector>
);

export default memo(EpiserverBlock);
