import styled from 'styled-components';

const VisualCardBlockInlineWrapper = styled.div`
  .inline-vcb {
    display: flex;
    flex-direction: column;
    align-items: center;

    .inline-vcb-title {
      padding-bottom: 32px;
      text-align: center;

      p {
        color: ${({ theme }) => theme.colors.neutralBlack};
        font-size: 28px;
        font-weight: 300;
        line-height: 33px;
        margin-bottom: 32px;
        text-align: center;
      }
    }

    .inline-vcb-title-underline {
      width: 106px;
      border: 0px none white;
      border-top: 5px solid #f8485e;
      margin-top: 8px;
      margin-bottom: 0;
    }

    .inline-vcb-intro {
      p {
        color: ${({ theme }) => theme.colors.neutralCharcoal};
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 32px;
        text-align: center;
      }
    }

    .vcb-cards-grid {
      width: 100%;
    }
  }

  @media (max-width: 1024px) {
    .inline-vcb {
      .vcb-card-small,
      .vcb-card-large,
      .vcb-card-medium {
        margin-bottom: 20px;
      }
    }
  }

  @media (min-width: 1024px) {
    .inline-vcb {
      .inline-vcb-title {
        p {
          font-size: 38px;
          line-height: 50px;
          max-width: 640px;
        }
      }

      .inline-vcb-intro {
        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          margin-bottom: 50px;
          max-width: 640px;
        }
      }

      .vcb-cards-grid {
        display: grid;
        grid-template-columns: repeat(12, [col-start] 1fr);
        gap: 32px;

        .vcb-card-small {
          grid-column: span 3;
        }

        .vcb-card-medium {
          grid-column: span 4;
        }

        .vcb-card-large {
          grid-column: span 6;
        }
      }
    }
  }
`;

export default VisualCardBlockInlineWrapper;
