import { IVisualCard } from './VisualCard.props';
import { theme, Typography } from '@weirgroup/weir-dls';
import StyledPrimaryIconButton from '../Buttons/StyledPrimaryIconButton';
import { FC } from 'react';
import { ThemeProvider } from 'styled-components';
import Image from '../ConditionalImage'; // TODO change
import {
  Link,
  VisualCardWrapper,
  BackgroundImageWrapper,
  Overlay,
} from './VisualCard.styles';

const VisualCard: FC<IVisualCard> = ({
  listingImage,
  listingTitle,
  listingIntro,
  targetUrl,
  showTealFadeOnCards,
}: IVisualCard) => (
  <ThemeProvider theme={theme}>
    <Link
      $backgroundImageUrl={listingImage}
      href={targetUrl}
      rel="noreferrer"
      target="_self"
    >
      <BackgroundImageWrapper>
        <Overlay />
        {listingImage && <Image src={listingImage} alt="" />}
      </BackgroundImageWrapper>

      <VisualCardWrapper className={`${showTealFadeOnCards && 'blue-fade'}`}>
        <div className="visual-card-inner">
          <div className="visual-card-content">
            <div className="visual-card-title">
              <Typography variant="DXP-H4">{listingTitle}</Typography>
            </div>
            <div className="visual-card-description">
              <Typography variant="DXP-P1">{listingIntro}</Typography>
            </div>
          </div>
          <div className="visual-card-icon-button">
            <StyledPrimaryIconButton
              iconName="DXPArrowRight"
              title=" "
              size="md"
            />
          </div>
        </div>
      </VisualCardWrapper>
    </Link>
  </ThemeProvider>
);

export default VisualCard;
