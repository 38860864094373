import styled from 'styled-components';

const VisualCardWrapper = styled.div`
  &.blue-fade {
    background: linear-gradient(
      0deg,
      rgba(0, 156, 170, 0.9) 29.12%,
      rgba(0, 94, 184, 0) 67.36%
    );
  }
  display: flex;
  flex-direction: column-reverse;
  flex-grow: 1;
  z-index: 10;

  .visual-card-inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .visual-card-content {
      margin: 32px;
      margin-right: 75px;
      word-break: break-word;

      .visual-card-title {
        p {
          color: ${({ theme }) => theme.colors.white};
          font-weight: 500;
          font-size: 28px;
          line-height: 35px;
          margin-bottom: 8px;
        }
      }

      .visual-card-description {
        p {
          color: ${({ theme }) => theme.colors.white};
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }

    .visual-card-icon-button {
      button {
        border-radius: 0;
        height: 68px;
        width: 68px;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }
`;

const Link = styled.a<{ $backgroundImageUrl: string }>`
  position: relative;
  overflow: hidden;
  background-size: cover;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 440px;
  width: 100%;
  text-decoration: none;

  @media (min-width: 1024px) {
    border-radius: 0 20px 0 20px;
  }
`;

const BackgroundImageWrapper = styled.div`
  position: absolute;
  background-size: cover;
  height: 100%;
  width: 100%;

  .image-container {
    height: 100%;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
`;

const Overlay = styled.span`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)),
    linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 84.43%);
`;

export { Link, VisualCardWrapper, BackgroundImageWrapper, Overlay };
