/* eslint-disable react/no-array-index-key */
import { IIndicatorDots } from './IndicatorDots.props';
import { FC } from 'react';
import { theme } from '@weirgroup/weir-dls';
import { ThemeProvider } from 'styled-components';
import IndicatorDotsWrapper from './IndicatorDots.styles';

const IndicatorDots: FC<IIndicatorDots> = ({
  numberOfDots,
  activeDotIndex,
  onDotClick,
  className,
}: IIndicatorDots) => {
  const handleKeyPress = (e: React.KeyboardEvent, i: number) => {
    if (e.key === 'Enter') {
      onDotClick(i);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <IndicatorDotsWrapper className={className}>
        {[...Array(numberOfDots)].map((x, i) => (
          <div
            key={i}
            tabIndex={0}
            data-testid="dls-indicator-dot"
            className={`indicator-dot ${
              activeDotIndex === i ? 'is-active' : ''
            }`}
            onClick={() => onDotClick(i)}
            onKeyPress={(e) => handleKeyPress(e, i)}
            role="button"
            aria-label={`Indicator dot ${i}`}
          />
        ))}
      </IndicatorDotsWrapper>
    </ThemeProvider>
  );
};

export default IndicatorDots;
