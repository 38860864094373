import { ReactElement, Suspense, memo } from 'react';
import { lazy } from 'react';
import Loader from '../../Loader/Loader';
import MyAppDataBlockProps from './MyAppDataBlock.props';

const MyAppDataBlock = ({
  content,
  mode,
}: MyAppDataBlockProps): ReactElement => {
  const HubPage = lazy(
    () => import('../../../modules/Hub/containers/HubPage.container')
  );

  return (
    <Suspense fallback={<Loader />}>
      <HubPage content={content} />
    </Suspense>
  );
};

export default memo(MyAppDataBlock);
