// https://react-hooks-testing-library.com/usage/basic-hooks
import { useEffect, useState } from 'react';

interface DeviceValues {
  readonly isMobile: boolean;
  readonly isTablet: boolean;
  readonly isDesktop: boolean;
  readonly carouselCardsPerViewThreshold: number;
}

interface Breakpoints {
  readonly tablet?: number;
  readonly desktop?: number;
}

const checkBreakpoints = ({
  breakpoints = {},
}: {
  breakpoints?: Breakpoints;
}): string => {
  const { tablet = 768, desktop = 1024 } = breakpoints;

  const width = window.innerWidth;
  let device = 'mobile';

  if (width < tablet) {
    device = 'mobile';
  } else if (width >= tablet && width < desktop) {
    device = 'tablet';
  } else if (width >= desktop) {
    device = 'desktop';
  }
  return device;
};

const useDeviceResize = (
  carouselbreakpoints?: any,
  thresholdPropertyName?: string
): DeviceValues => {
  const breakpoints = { tablet: 768, desktop: 1024 };
  const [isDevice, setDevice] = useState(checkBreakpoints({ breakpoints }));
  const [checkBreakpointVal, setBreakpointVal] = useState(
    checkSlidesNumberInView(carouselbreakpoints, thresholdPropertyName)
  );

  useEffect(() => {
    const handleChangeSize = () => {
      const getDevice = checkBreakpoints({ breakpoints });
      setDevice(getDevice);
      const val = checkSlidesNumberInView(
        carouselbreakpoints,
        thresholdPropertyName
      );
      setBreakpointVal(val);
    };

    window.addEventListener('resize', handleChangeSize);

    return () => {
      window.removeEventListener('resize', handleChangeSize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDevice, checkBreakpointVal]);

  const isMobile = isDevice === 'mobile';
  const isTablet = isDevice === 'tablet';
  const isDesktop = isDevice === 'desktop';

  return {
    isMobile,
    isTablet,
    isDesktop,
    carouselCardsPerViewThreshold: checkBreakpointVal,
  };
};

const checkSlidesNumberInView = (
  breakpoints: any,
  thresholdPropertyName?: string
) => {
  if (!breakpoints) return;
  const width = window.innerWidth;
  const breakpointsArray = Object.keys(breakpoints);

  const breakpointValue = breakpointsArray.find((el, index) => {
    if (index < breakpointsArray.length - 1) {
      return +el <= width && +breakpointsArray[index + 1] > width;
    } else {
      return +el <= width;
    }
  });

  const breakpoint = breakpointValue && breakpoints[breakpointValue];
  return (
    breakpoint && thresholdPropertyName && breakpoint[thresholdPropertyName]
  );
};

export default useDeviceResize;
