import { FC, lazy, Suspense } from 'react';
import { ThemeProvider } from 'styled-components';
import { theme, Typography } from '@weirgroup/weir-dls';

import Image from '../Image/ImageComponent';
import { IFeatureItem } from '../FeatureBlock/FeatureBlock.props';
import RichText from '../RichText/RichText';
import StyledPrimaryButton from '../Buttons/StyledPrimaryButton';
import FeatureSlide from './FeatureSlide.styles';

const VideoPlayer = lazy(() => import('../VideoPlayer/VideoPlayer'));
const FeatureBlock: FC<IFeatureItem> = ({
  allowAlign,
  cta,
  description,
  imageUrl,
  mediaPosition,
  squareColour,
  title,
  video,
  enableAlternativeBackground,
}: IFeatureItem) => {
  return (
    <ThemeProvider theme={theme}>
      <FeatureSlide tabIndex={-1}>
        <div
          className={`feature-block ${
            allowAlign && mediaPosition === 'Right' ? 'Right' : ''
          }`}
        >
          <div className="item media-item">
            <div className={`brand-indicator brand ${squareColour}`} />

            <div className="media-container">
              {video && (
                <Suspense fallback={<div>Loading... </div>}>
                  <VideoPlayer thumbnailUrl={imageUrl} video={video} />
                </Suspense>
              )}
              {!video && imageUrl && (
                <Image className="image" src={imageUrl} noLazy={true} />
              )}
            </div>
          </div>

          <div className="item">
            <div className="content-area">
              <div className="content-area-inner">
                <div className="feature-block-title">
                  <Typography variant="DXP-H2">{title}</Typography>
                </div>

                <div className="feature-block-description">
                  <RichText>{description}</RichText>
                </div>
              </div>

              {cta?.url && cta?.caption && (
                <a
                  href={cta.url}
                  target={cta.openInNewTabWindow ? '_blank' : '_self'}
                  className="feature-block-cta"
                  rel="noreferrer"
                  data-testid="feature-block-cta"
                  tabIndex={-1}
                >
                  <StyledPrimaryButton
                    variant="primary"
                    label={cta.caption}
                    size="md"
                    tabIndex={-1}
                  />
                </a>
              )}
            </div>
          </div>

          {!enableAlternativeBackground && <div className="decorative-line" />}
        </div>
      </FeatureSlide>
    </ThemeProvider>
  );
};

export default FeatureBlock;
