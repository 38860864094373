import { ReactElement, memo } from 'react';
import EpiserverContentArea from '../../EpiserverContentArea';
import { addEditAttributes } from '../../../../services/Optimizely/utils/episerverAttributes';
import { PortalNewsPageContent } from './PortalNewsPage.props';
import { PageWrapper } from '../ContentComponent/ContentComponent.styles';

const PortalNewsPage = ({
  content,
  mode,
}: PortalNewsPageContent): ReactElement => {
  return (
    <PageWrapper>
      <div className="contentAlign">
        <main className="Page-container">
          <div {...addEditAttributes('SocialShareReference')}>
            <EpiserverContentArea
              content={content.socialShareReference}
              mode={mode}
            />
          </div>
          <div {...addEditAttributes('ContentArea')}>
            <EpiserverContentArea content={content.contentArea} mode={mode} />
          </div>
          <div {...addEditAttributes('CardLinkReference')}>
            <EpiserverContentArea
              content={content.cardLinkReference}
              mode={mode}
            />
          </div>
          <div {...addEditAttributes('CardListReference')}>
            <EpiserverContentArea
              content={content.cardListReference}
              mode={mode}
            />
          </div>
          <div {...addEditAttributes('SecondaryContentArea')}>
            <EpiserverContentArea
              content={content.secondaryContentArea}
              mode={mode}
            />
          </div>
        </main>
      </div>
    </PageWrapper>
  );
};

export default memo(PortalNewsPage);
