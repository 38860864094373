import React, { ReactElement, memo } from 'react';

import ConditionalImageProps from './ConditionalImage.props';

export function AbsoluteImage(src: string): string {
  var url: string;

  if (typeof src === 'string') {
    url =
      (/(http(s?)):\/\//i.test(src) ? '' : process.env.REACT_APP_WEBSITE_URL) +
      src;
  } else url = src;

  return url;
}

const ConditionalImage = ({
  src,
  alt,
  ...props
}: ConditionalImageProps): ReactElement => {
  var url: string;

  if (typeof src === 'string') {
    url =
      (/(http(s?)):\/\//i.test(src) ? '' : process.env.REACT_APP_WEBSITE_URL) +
      src;
  } else url = src;

  return <>{src && <img src={url} alt={alt} {...props} />}</>;
};

export default memo(ConditionalImage);
