import { ReactElement, memo } from 'react';
import TextBlockWrapperProps from './TextBlockWrapper.props';
import TextBlock from '../TextBlock/TextBlock';

const TextBlockWrapper = ({
  content,
  mode,
}: TextBlockWrapperProps): ReactElement => {
  return (
    <TextBlock
      title={content.title}
      text={content.text?.html}
      textLayout={content.textLayout}
      enableAlternativeBackground={content.enableAlternativeBackground}
    />
  );
};

export default memo(TextBlockWrapper);
