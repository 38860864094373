import { ReactElement, memo } from 'react';
import DecorativeCarouselWrapperProps from './DecorativeCarouselWrapper.props';
import DecorativeCarousel from '../DecorativeCarousel/DecorativeCarousel';
import { AbsoluteImage } from '../ConditionalImage/ConditionalImage';

const DecorativeCarouselWrapper = ({
  content,
  mode,
}: DecorativeCarouselWrapperProps): ReactElement => {
  const expandedContent = content.images.map((c) => ({
    imageUrl: AbsoluteImage(
      c.contentLink.expanded.url + '?width=700&format=webp&quality=90'
    ),
    altText: c.contentLink.expanded.altText,
    title: c.contentLink.expanded.title,
  }));

  content.images = expandedContent;

  return <DecorativeCarousel {...content} />;
};

export default memo(DecorativeCarouselWrapper);
