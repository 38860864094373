import { FC, useCallback, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { SwiperSlide } from 'swiper/react';
import { theme } from '@weirgroup/weir-dls';
import CarouselArrows from '../CarouselArrows/CarouselArrows';
import useDeviceResize from '../../../services/Optimizely/types/useDeviceResize';
import Carousel from '../Carousel/Carousel';
import ComponentLayout from '../Layout/ComponentLayout';
import { ICarouselOnSlideChange } from '../Carousel/Carousel.props';
import { IFeatureBlock } from '../FeatureBlock/FeatureBlock.props';
import { IFeatureItem } from './FeatureBlock.props';
import FeatureSlide from '../FeatureSlide/FeatureSlide';
import {
  FeatureBlockWrapper,
  FeatureComponentWrapper,
} from './FeatureBlock.styles';

const FeatureBlock: FC<IFeatureBlock> = ({
  items,
  isVariant,
  enableAlternativeBackground,
}: IFeatureBlock) => {
  const [isStart, setIsStart] = useState<boolean>(true);
  const [isEnd, setIsEnd] = useState<boolean>(false);
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(-1);
  const [slideToChange, setSlideToChange] = useState<number>(-1);
  const { isDesktop } = useDeviceResize();

  const handleSlideChange = useCallback((instance: ICarouselOnSlideChange) => {
    setIsStart(instance.start);
    setIsEnd(instance.end);
    setActiveSlideIndex(instance.active);
  }, []);

  const goToSlide = useCallback((slide: number) => {
    setSlideToChange(slide + 1);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <FeatureComponentWrapper
        className={`feature-block ${isVariant && 'brand secondary'} 
        ${enableAlternativeBackground && 'showGreyBg'}`}
      >
        <ComponentLayout>
          <FeatureBlockWrapper>
            <Carousel
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
              }}
              centeredSlides={false}
              loop={false}
              onSlideChange={handleSlideChange}
              pagination={!isDesktop}
              slideToChange={slideToChange}
            >
              {items?.map((item: IFeatureItem) => (
                <SwiperSlide
                  key={item.title}
                  className="swiper-slides"
                  tabIndex={-1}
                >
                  <FeatureSlide
                    {...item}
                    allowAlign={items.length === 1}
                    enableAlternativeBackground={enableAlternativeBackground}
                  />
                </SwiperSlide>
              ))}
            </Carousel>

            {isDesktop && items?.length > 1 && (
              <div className="carousel-buttons">
                <CarouselArrows
                  isStart={isStart}
                  isEnd={isEnd}
                  activeSlide={activeSlideIndex}
                  onSlideChange={goToSlide}
                />
              </div>
            )}
          </FeatureBlockWrapper>
        </ComponentLayout>
      </FeatureComponentWrapper>
    </ThemeProvider>
  );
};

export default FeatureBlock;
