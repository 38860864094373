import styled from 'styled-components';

const BackgroundImageWrapper = styled.div`
  width: 100%;
  height: auto;

  picture {
    > img {
      width: 100%;
      height: auto;
    }
  }
`;

export default BackgroundImageWrapper;
