import { msalInstance } from '../../../services/msalConfig';
import { msalConstants } from '../../../services/msal.constants';

export async function exampleCloudflareHeader(): Promise<Record<string, any>> {
  return {
    'CF-IPCountry': 'au',
  };
}

export async function getAccessToken(): Promise<any> {
  var request = {
    scopes: msalConstants.portalApplicationConfig.scopes,
  };

  var result = await msalInstance
    .acquireTokenSilent(request)
    .catch((error) => {});

  return result?.accessToken;
}
