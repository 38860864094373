import { IVisualCardBlock } from '../VisualCardBlock/VisualCardBlock.props';
import VisualCard from '../VisualCard/VisualCard';
import { theme, Typography } from '@weirgroup/weir-dls';
import { FC } from 'react';
import { ThemeProvider } from 'styled-components';
import VisualCardBlockInlineWrapper from './VisualCardBlockInline.styles';
import RichText from '../RichText/RichText';

const VisualCardBlockInline: FC<IVisualCardBlock> = ({
  title,
  introduction,
  cardSize,
  cards,
  showTealFadeOnCards,
}: IVisualCardBlock) => {
  return (
    <ThemeProvider theme={theme}>
      <VisualCardBlockInlineWrapper>
        <div className="inline-vcb">
          {title && (
            <div className="inline-vcb-title">
              <Typography variant="DXP-H2">
                <RichText>{title}</RichText>
              </Typography>
              <hr className="inline-vcb-title-underline" />
            </div>
          )}
          {introduction && (
            <div className="inline-vcb-intro">
              <Typography variant="DXP-P1">{introduction}</Typography>
            </div>
          )}
          <div className="vcb-cards-grid">
            {cards.map((card) => (
              <div
                className={`vcb-card-${cardSize?.toLowerCase()}`}
                key={card.listingTitle}
              >
                <VisualCard
                  {...card}
                  showTealFadeOnCards={showTealFadeOnCards}
                />
              </div>
            ))}
          </div>
        </div>
      </VisualCardBlockInlineWrapper>
    </ThemeProvider>
  );
};

export default VisualCardBlockInline;
