import { FC, ReactNode } from 'react';
import Layout from './ComponentLayout.styles';

interface Props {
  readonly children: ReactNode;
  readonly noMargin?: boolean;
  readonly noPadding?: boolean;
  readonly fullHeight?: boolean;
  readonly noOverflow?: boolean;
  readonly backgroundColor?: string;
}

const ComponentLayout: FC<Props> = ({
  children,
  noMargin,
  noPadding,
  backgroundColor,
  fullHeight,
  noOverflow,
}: Props) => (
  <Layout
    $noMargin={!!noMargin}
    $noPadding={!!noPadding}
    $fullHeight={!!fullHeight}
    $noOverflow={!!noOverflow}
    $backgroundColor={backgroundColor}
  >
    {children}
  </Layout>
);

ComponentLayout.defaultProps = {
  noMargin: false,
  noPadding: false,
  fullHeight: false,
  noOverflow: false,
  backgroundColor: '',
};

export default ComponentLayout;
