import { ReactElement, memo } from 'react';
import HelpfulLinksBlockProps from './HelpfulLinksBlock.props';
import HelpfulLinksBlockWrapper from './HelpfulLinksBlock.styles';
import { TitleWrapper } from '../pages/ContentComponent/ContentComponent.styles';
import { addEditAttributes } from '../../../services/Optimizely/utils/episerverAttributes';
import EpiserverContentArea from '../EpiserverContentArea';

const HelpfulLinksBlock = ({
  content,
  mode,
}: HelpfulLinksBlockProps): ReactElement => {
  return (
    <HelpfulLinksBlockWrapper>
      {content.title && (
        <TitleWrapper {...addEditAttributes('Title')}>
          <h2>{content.title}</h2>
        </TitleWrapper>
      )}
      <div {...addEditAttributes('ContentArea')}>
        <div className="links-panel">
          <EpiserverContentArea content={content.contentArea} mode={mode} />
        </div>
      </div>
    </HelpfulLinksBlockWrapper>
  );
};

export default memo(HelpfulLinksBlock);
