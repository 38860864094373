import styled from 'styled-components';

const TextBlockComponentWrapper = styled.div`
  &.brand {
    a {
      color: white;
    }
  }

  section {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  @media (min-width: 768px) {
    section {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 4rem;
      padding-bottom: 4rem;
    }
  }
`;

const TextBlockWrapper = styled.div`
  font-family: Roboto, sans-serif;

  hr {
    border: 0;
    border-top: 1px solid ${({ theme }) => theme.colors.dust};
    margin: 0;
  }

  .check-list,
  .check-list-product {
    color: ${({ theme }) => theme.colors.neutralCharcoal};
    column-count: 2;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    padding-bottom: 44px;
  }

  .check-list-product {
    list-style: none !important; //over-riding pasted text styling

    li:before {
      background-image: url('../../../resources/images/check-list.svg');
      background-size: 16px 12px;
      content: '';
      display: inline-flex;
      height: 12px;
      margin-left: -22px;
      margin-right: 6px;
      width: 16px;
    }
  }

  ul span {
    color: ${({ theme }) =>
      theme.colors
        .neutralCharcoal} !important; //over-riding pasted text styling
    font-family: Roboto, sans-serif !important; //over-riding pasted text styling
    font-size: 16px !important; //over-riding pasted text styling
    font-weight: 400;
    line-height: 22px;
  }

  ul ul {
    margin-bottom: 0;
  }

  .heading-left h2.left-heading,
  .two-columns h2.left-heading {
    color: ${({ theme }) => theme.colors.onyx};
    font-weight: 300;
    font-size: 28px;
    line-height: 33px;
    margin: 0 0 20px 0;
  }

  @media (min-width: 1024px) {
    .check-list,
    .check-list-product {
      column-count: 4;
      font-size: 16px;
      line-height: 27px;
      padding-bottom: 80px;
    }

    .check-list li,
    .check-list-product li {
      padding-right: 20px;
    }

    .heading-left h2.left-heading,
    .two-columns h2.left-heading {
      font-size: 38px;
      line-height: 50px;
      margin: 0 0 66px 0;
      text-align: center;
      padding-right: 20px;
      font-weight: 300;
    }

    .heading-left {
      display: grid;
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }

    .heading-left h2.left-heading {
      text-align: left;
      grid-column: span 3 / span 3;
      word-wrap: break-word;
      hyphens: auto;
    }

    .heading-left .rich-text {
      grid-column: span 9 / span 9;
      color: ${({ theme }) => theme.colors.neutralCharcoal};
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      margin-bottom: 20px;
      max-width: 80%;
    }

    .two-columns .columns {
      column-count: 2;
    }
  }

  .rich-text img {
    max-width: 100%;
    height: auto;
  }
`;

const GreyBackgroundWrapper = styled.div`
  &.showGreyBg {
    background: #f0f0f0;
    padding: 30px 0;
  }
`;

export { TextBlockWrapper, TextBlockComponentWrapper, GreyBackgroundWrapper };
