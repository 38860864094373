import React, { FunctionComponent, ReactElement, memo } from 'react';

import EpiContent from '../../../services/Optimizely/types/EpiContent';
import getComponentTypeForContent from '../../../services/Optimizely/utils/getComponentTypeForContent';

import EpiserverGenericBlock from '../EpiserverGenericBlock';
import BlockComponentSelectorProps from './BlockComponentSelector.props';

const BlockComponentSelector = ({
  content,
  mode,
  previewWrapper = false,
}: BlockComponentSelectorProps): ReactElement => {
  if (content) {
    const BlockComponent = getComponentTypeForContent(
      content,
      mode,
      previewWrapper
    ) as FunctionComponent<EpiContent>;

    if (BlockComponent) {
      return <BlockComponent content={content} mode={mode} />;
    }
  }

  if (content) return <EpiserverGenericBlock content={content} />;

  return <></>;
};

export default memo(BlockComponentSelector);
