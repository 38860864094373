import { ReactElement, memo } from 'react';

import ConditionalImage from '../ConditionalImage';
import ImageFileProps from './ImageFile.props';

const ImageFile = ({ content }: ImageFileProps): ReactElement => {
  return (
    <div className={'wrapper'}>
      <ConditionalImage src={content.url} alt="" />
    </div>
  );
};

export default memo(ImageFile);
