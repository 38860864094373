import styled from 'styled-components';

const FeatureSlide = styled.div`
  width: 100%;

  .feature-block {
    display: flex;
    flex-direction: column;
    height: auto;

    @media (min-width: 768px) {
      flex-direction: row;
      height: 100%;
      gap: 40px;

      &.Right {
        @media (min-width: 768px) {
          flex-direction: row-reverse;
        }

        .image {
          border-radius: 0 0 0 36px;

          @media (min-width: 1024px) {
            border-radius: 0 0 0 60px;
          }
        }

        .brand-indicator {
          left: initial;
          right: 0;
        }
      }
    }

    .item {
      position: relative;
      box-sizing: border-box;
      height: 100%;

      @media (min-width: 768px) {
        flex: 0 0 calc(50% - 20px);
        display: flex;
      }

      @media (min-width: 1024px) {
        padding: 32px 0;
      }
    }

    .media-container {
      padding: 20px 0 36px;
      display: inline-block;
      width: 100%;
      display: flex;

      @media (min-width: 1024px) {
        padding: 0px;
      }
    }

    .image {
      border-radius: 0 0 36px 0;
      position: relative;
      width: 100%;
      aspect-ratio: 16/9;
      object-fit: cover;

      @media (min-width: 1024px) {
        border-radius: 0 0 60px 0;
      }
    }

    .content-area {
      display: flex;
      flex-direction: column;
      justify-content: between;
      text-align: left;
      width: 100%;
      height: 100%;

      @media (min-width: 768px) {
        justify-content: center;
      }

      a {
        width: fit-content;
      }
    }

    .content-area-inner {
      flex: 1 1 auto;

      @media (min-width: 768px) {
        flex: 0;
        padding-bottom: 32px;
      }
    }

    .feature-block-title {
      p {
        color: ${({ theme }) => theme.colors.onyx};
        font-size: ${({ theme }) => theme.fontsize.pt28};
        font-weight: 300;

        @media (min-width: 1280px) {
          font-size: ${({ theme }) => theme.fontsize.pt38};
        }
      }
    }

    .feature-block-description {
      color: ${({ theme }) => theme.colors.neutralCharcoal};
      font-size: ${({ theme }) => theme.fontsize.pt16};
      font-family: ${({ theme }) => theme.fonts.roboto};
      line-height: 22px;
      margin-top: 16px;
    }

    .feature-block-cta {
      display: inline-block;
      margin-top: 32px;
      text-decoration: none;

      @media (min-width: 1024px) {
        margin-bottom: 32px;
      }
    }
  }

  .brand-indicator {
    width: 100%;
    height: 100%;
    max-width: 48px;
    max-height: 48px;
    position: absolute;
    background-color: ${({ theme }) => theme.colors.weirBlueAccent};
    z-index: 30;
    top: 0;
    left: 0;
    opacity: 0.9;

    @media (min-width: 1024px) {
      max-width: 100px;
      max-height: 100px;
    }

    &.Red {
      background-color: #cb1a23;
    }

    &.Orange {
      background-color: #ed8b00;
    }

    &.Teal {
      background-color: #00a7b5;
      opacity: 1;
    }
  }
`;

export default FeatureSlide;
