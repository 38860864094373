import { IconButton } from '@weirgroup/weir-dls';
import { IconButtonProps } from '@weirgroup/weir-dls/dist/components/IconButton/IconButton';
import { JSX } from 'react/jsx-runtime';
import styled from 'styled-components';

const StyledPrimaryIconButtonWrapper = styled.span`
  button:not([disabled]):not(:hover),
  a:not(:hover) {
    background: linear-gradient(135deg, #002e5d 1.13%, #005eb8 100.75%);
  }
  button,
  button:hover {
    border: 0px;
  }
`;

const StyledPrimaryIconButton = (
  props: JSX.IntrinsicAttributes & IconButtonProps
) => (
  <StyledPrimaryIconButtonWrapper>
    <IconButton {...props} />
  </StyledPrimaryIconButtonWrapper>
);

export default StyledPrimaryIconButton;
