import { ReactElement, memo } from 'react';
import FeatureBlockProps, {
  IFeatureBlock,
  IFeatureItem,
  ILink,
} from './FeatureBlock.props';
import FeatureBlock from './FeatureBlock';
import { IVideoItem } from '../VideoPlayer/VideoPlayer.props';
import { AbsoluteImage } from '../ConditionalImage/ConditionalImage';

const FeatureBlockWrapper = ({
  content,
  mode,
}: FeatureBlockProps): ReactElement => {
  const expandedContent = content.features.map<IFeatureItem>((f) => ({
    title: f.contentLink.expanded.title,
    imageUrl: AbsoluteImage(f.contentLink.expanded.image?.url),
    enableAlternativeBackground: false,
    description: f.contentLink.expanded.description?.html,
    squareColour: f.contentLink.expanded.squareColour,
    mediaPosition: f.contentLink.expanded.mediaPosition,
    ...(f.contentLink.expanded.video?.url && {
      video: { videoUrl: f.contentLink.expanded.video.url } as IVideoItem,
    }),
    cta: {
      url: f.contentLink.expanded.cta?.url,
      openInNewTabWindow: f.contentLink.expanded.cta?.openInNewTabWindow,
      caption: f.contentLink.expanded.cta?.caption,
    } as ILink,
  }));

  const featureContent = {
    isVariant: true,
    enableAlternativeBackground: true,
    items: expandedContent,
  } as IFeatureBlock;

  console.log('Opti featureblock: ', expandedContent);

  return <FeatureBlock {...featureContent} />;
};

export default memo(FeatureBlockWrapper);
