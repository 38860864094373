import { ReactElement, memo } from 'react';
import { Footer } from '@weirgroup/weir-dls';
import FooterWrapperProps from './FooterWrapperProps';

const FooterWrapper = ({ navigation }: FooterWrapperProps): ReactElement => (
  <Footer
    logoName="WeirLogo"
    InformationText={navigation?.i18n?.siteInformation}
    CopyrightText={
      !!navigation?.i18n?.copyright
        ? navigation?.i18n?.copyright
        : 'The Weir Group PLC, 1 West Regent Street, Glasgow, G2 1RW. Company Number SC002934'
    }
    NavigationLinks={navigation?.items}
  />
);

export default memo(FooterWrapper);
