import {
  ContextMode,
  ContentData,
  ResolvedContent,
  ResolvedContentStatus,
} from '@episerver/content-delivery';
import { lazy } from 'react';
import BlockComponentSelector from '../../BlockComponentSelector';

const ErrorPage = lazy(() => import('../../../../modules/ErrorPage/ErrorPage'));

const ContentComponent = (content: ResolvedContent<ContentData>) => {
  switch (content?.status) {
    case ResolvedContentStatus.NotFound:
    case ResolvedContentStatus.AccessDenied:
    case ResolvedContentStatus.Unauthorized:
      return <ErrorPage />;
    default:
      break;
  }

  var renderPreviewWrapper: boolean =
    content?.mode === ContextMode.Preview &&
    content?.content?.contentType[0] === 'Block';

  return (
    <>
      {content?.status === ResolvedContentStatus.Resolved && (
        <>
          <BlockComponentSelector
            content={content.content}
            mode={content?.mode}
            previewWrapper={renderPreviewWrapper}
          />
        </>
      )}
    </>
  );
};

export default ContentComponent;
