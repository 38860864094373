import styled from 'styled-components';

const IndicatorDotsWrapper = styled.div`
  .indicator-dot {
    width: 10px;
    height: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.slate};
    border-radius: 100%;
    display: inline-block;
    margin-right: 8px;
    cursor: pointer;

    &:last-of-type {
      margin-right: 0;
    }

    &.is-active {
      background-color: ${({ theme }) => theme.colors.slate};
    }
  }
`;

export default IndicatorDotsWrapper;
