import { ContextMode } from '@episerver/content-delivery';
import { ReactElement, memo } from 'react';

import classNames from '../../../services/Optimizely/utils/classNames';

import BlockComponentSelector from '../BlockComponentSelector';
import EpiserverBlockPreviewProps from './EpiserverBlockPreview.props';
import BlockPreviewWrapper from './EpiserverBlockPreview.styles';

const EpiserverBlockPreview = ({
  content,
  mode,
}: EpiserverBlockPreviewProps): ReactElement => {
  return (
    <BlockPreviewWrapper>
      <section className="wrapper">
        <div className="border">
          <h5>Full</h5>
        </div>
        <div className="container">
          <BlockComponentSelector
            mode={ContextMode.Edit}
            content={content}
          ></BlockComponentSelector>
        </div>

        <div className="border">
          <h5>Wide</h5>
        </div>
        <div className={classNames(['container', 'container--two-third'])}>
          <BlockComponentSelector
            mode={ContextMode.Edit}
            content={content}
          ></BlockComponentSelector>
        </div>

        <div className="border">
          <h5>Half</h5>
        </div>
        <div className={classNames(['container', 'container--half'])}>
          <BlockComponentSelector
            mode={ContextMode.Edit}
            content={content}
          ></BlockComponentSelector>
        </div>

        <div className="border">
          <h5>Narrow</h5>
        </div>
        <div className={classNames(['container', 'container--one-third'])}>
          <BlockComponentSelector
            mode={ContextMode.Edit}
            content={content}
          ></BlockComponentSelector>
        </div>
      </section>
    </BlockPreviewWrapper>
  );
};

export default memo(EpiserverBlockPreview);
