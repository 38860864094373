/* eslint-disable react/no-danger */
import ComponentLayout from '../Layout/ComponentLayout';
import { IHeroBasicBlock } from './HeroBasicBlock.props';
import BackgroundImage from '../BackgroundImage/BackgroundImage';
import { theme, Typography } from '@weirgroup/weir-dls';
import { FC } from 'react';
import { ThemeProvider } from 'styled-components';
import useDeviceResize from '../../../services/Optimizely/types/useDeviceResize';
import RichText from '../RichText/RichText';
import {
  ContentWrapper,
  HeroBasicWrapper,
  TitleWrapper,
  HeroWrapper,
} from './HeroBasicBlock.styles';

const HeroBasicBlock: FC<IHeroBasicBlock> = ({
  altText,
  backgroundUrl,
  content,
  imagePosition,
  isVariant,
  title,
  showBlueFade,
}: IHeroBasicBlock) => {
  const { isDesktop } = useDeviceResize();
  const displayedImagePosition = (() => {
    if (!isDesktop) return 'full-width';
    if (!backgroundUrl) return 'no-image';
    return imagePosition;
  })();
  const isMobileAndHasImage = !isDesktop && backgroundUrl;
  return (
    <ThemeProvider theme={theme}>
      <HeroWrapper
        className={`hero-basic brand ${
          displayedImagePosition === 'full-width' ? 'full-width' : ''
        } ${isVariant && 'secondary'} ${
          backgroundUrl ? 'with-image' : 'no-image'
        } ${showBlueFade && 'blue-fade'}
        ${imagePosition === 'full-width' ? 'full-width-position' : 'no-full-width-position'}`}
        data-testid="HeroWrapper"
      >
        <ComponentLayout noMargin noPadding>
          {displayedImagePosition === 'full-width' && backgroundUrl && (
            <BackgroundImage
              className="full-width-image"
              src={backgroundUrl}
              alt={altText}
              preload
            />
          )}
          <HeroBasicWrapper
            className={`${displayedImagePosition} ${
              !backgroundUrl && 'no-image'
            } ${
              !backgroundUrl &&
              !content &&
              displayedImagePosition === 'no-image' &&
              'reduced-hero'
            }`}
          >
            {(!backgroundUrl ||
              displayedImagePosition === 'image-left' ||
              displayedImagePosition === 'image-right') && (
              <>
                <div className={`v-line ${displayedImagePosition}`} />
                {/* {breadcrumbs && (
                  <div className={`h-line ${displayedImagePosition}`} />
                )} */}
              </>
            )}

            {displayedImagePosition !== 'full-width' && backgroundUrl && (
              <BackgroundImage
                className={displayedImagePosition}
                src={backgroundUrl}
                alt={altText}
                preload
              />
            )}

            {/* {breadcrumbs && (
              <BreadcrumbWrapper className="breadcrumbs">
                <Breadcrumbs {...breadcrumbs} />
              </BreadcrumbWrapper>
            )} */}

            <div className={`content ${!backgroundUrl && 'no-image'}`}>
              <ComponentLayout noMargin>
                <TitleWrapper
                  className={`${displayedImagePosition} ${
                    isMobileAndHasImage ? 'text-white' : ''
                  }`}
                >
                  {title?.includes('<strong>') ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: title
                          .replace(/<strong>/g, '<span>')
                          .replace(/<\/strong>/g, '</span>'),
                      }}
                    />
                  ) : (
                    <RichText>{title}</RichText>
                  )}
                </TitleWrapper>

                {content && (
                  <ContentWrapper className={displayedImagePosition}>
                    <Typography color="weirBlue" variant="DXP-P1">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: content,
                        }}
                      />
                    </Typography>
                  </ContentWrapper>
                )}
              </ComponentLayout>
            </div>
          </HeroBasicWrapper>
        </ComponentLayout>
      </HeroWrapper>
    </ThemeProvider>
  );
};

export default HeroBasicBlock;
