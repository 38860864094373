import { ContentData, ContextMode } from '@episerver/content-delivery';
import { FunctionComponent } from 'react';

import ContentBlock from '../../../components/Optimizely/ContentBlock';
import EpiserverBlockPreview from '../../../components/Optimizely/EpiserverBlockPreview';
import EpiserverGenericBlock from '../../../components/Optimizely/EpiserverGenericBlock';
import ImageFile from '../../../components/Optimizely/ImageFile';
import EpiContent from '../types/EpiContent';

import HubStartPage from '../../../components/Optimizely/pages/HubStartPage';
import ApplicationPage from '../../../components/Optimizely/pages/ApplicationPage';
import PortalContentPage from '../../../components/Optimizely/pages/PortalContentPage';
import PortalNewsArticlePage from '../../../components/Optimizely/pages/PortalNewsArticlePage';
import PortalCampaignPage from '../../../components/Optimizely/pages/PortalCampaignPage';

import HelpfulLinksBlock from '../../../components/Optimizely/ActionLink/HelpfulLinkBlock';
import LinkBlock from '../../../components/Optimizely/ActionLink/LinkBlock';
import MyAccessBlock from '../../../components/Optimizely/MyAccess/MyAccessBlock';
import MyActivityBlock from '../../../components/Optimizely/MyActivity/MyActivityBlock';
import MyAppDataBlock from '../../../components/Optimizely/AppData/MyAppDataBlock';
import TextBlockWrapper from '../../../components/Optimizely/TextBlockWrapper/TextBlockWrapper';
import VisualCardBlockWrapper from '../../../components/Optimizely/VisualCardBlockWrapper/VisualCardBlockWrapper';
import RichTextVisualCardBlock from '../../../components/Optimizely/VisualCardBlockWrapper/RichTextVisualCardBlockWrapper';
import FeatureBlockWrapper from '../../../components/Optimizely/FeatureBlock/FeatureBlockWrapper';
import HeroBlockWrapper from '../../../components/Optimizely/HeroBlock/HeroBlockWrapper';
import DecorativeCarouselWrapper from '../../../components/Optimizely/DecorativeCarouselWrapper/DecorativeCarouselWrapper';

export const pages: Record<string, unknown | undefined> = {
  HubStartPge: HubStartPage,
  ApplicationPage: ApplicationPage,
  PortalContentPage: PortalContentPage,
  PortalNewsArticlePage: PortalNewsArticlePage,
  PortalCampaignPage: PortalCampaignPage,
};

// Block type in Optimizely on the right, React component on the left
export const blocks: Record<string, unknown | undefined> = {
  ContentBlock: ContentBlock,
  EpiserverGenericBlock: EpiserverGenericBlock,
  EpiserverBlockPreview: EpiserverBlockPreview,
  MyAppDataBlock: MyAppDataBlock,
  MyAccessBlock: MyAccessBlock,
  MyActivityBlock: MyActivityBlock,
  TextBlock: TextBlockWrapper,
  FeatureBlock: FeatureBlockWrapper,
  HelpfulLinksBlock: HelpfulLinksBlock,
  LinkBlock: LinkBlock,
  HeroBlock: HeroBlockWrapper,
  VisualCardBlock: VisualCardBlockWrapper,
  RichTextVisualCardBlock: RichTextVisualCardBlock,
  ImageCarouselBlock: DecorativeCarouselWrapper,
};

export const other: Record<string, unknown | undefined> = {
  ImageFile: ImageFile,
};

const getComponentTypeForContent = (
  content: ContentData | undefined,
  mode?: ContextMode,
  previewWrapper?: boolean
): FunctionComponent<EpiContent> | unknown => {
  content?.contentType.join('');
  if (content) {
    const type = content?.contentType[0];
    const template = content?.contentType.slice(-1)[0]; // Get the content of the last element in the array

    if (type === 'Page') {
      if (template && pages[template]) {
        return pages[template] as FunctionComponent;
      }
    } else if (type === 'Block') {
      if (template && blocks[template]) {
        if (previewWrapper) return EpiserverBlockPreview;
        else return blocks[template] as FunctionComponent;
      }
    } else {
      if (template && other[template]) {
        return other[template] as FunctionComponent;
      }
    }
  }

  return undefined;
};

export default getComponentTypeForContent;
