import styled from 'styled-components';

const HelpfulLinksBlockWrapper = styled.div`
  .links-panel {
    padding: 20px;
  }
  .links-panel .container {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  .link {
    display: inline-block;
    width: 21.09vw;
  }
`;

export default HelpfulLinksBlockWrapper;
