import { ICarousel } from './Carousel.props';
import { theme, Icon } from '@weirgroup/weir-dls';
import React, { useState, useEffect, useCallback } from 'react';
import { ThemeProvider } from 'styled-components';
import SwiperCore, { Keyboard, A11y, Pagination, Navigation } from 'swiper';
import { Swiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/a11y';
import CarouselArrows from '../CarouselArrows/CarouselArrows';
import {
  CarouselWrapper,
  DotsWrapper,
  CarouselCustomPagination,
} from './Carousel.styles';

const Carousel: React.FC<ICarousel> = ({
  loop = false,
  pagination = false,
  paginationType,
  breakpoints,
  arrows = false,
  centeredSlides = false,
  keyboard = true,
  children,
  onSlideChange,
  setSlideToChange,
  slideToChange,
  spaceBetween = 0,
}: ICarousel) => {
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>();
  const [isStart, setIsStart] = useState<boolean>(true);
  const [isEnd, setIsEnd] = useState<boolean>(false);
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
  const [numberOfSlides, setNumberOfSlides] = useState<number>(0);

  const goToSlide = useCallback(
    (index: number) => {
      swiperInstance?.slideTo(index);
    },
    [swiperInstance]
  );

  useEffect(() => {
    if (slideToChange) {
      // NOTE: If we recieve 0 from parent component, it will treat it as false and not execute function so we change the number here
      goToSlide(slideToChange - 1);
    }
  }, [goToSlide, slideToChange]);

  const updateElements = (instance: typeof swiperInstance) => {
    if (instance) {
      const slidesCount = loop
        ? instance.slides.length - (instance.loopedSlides ?? 0) * 2
        : instance.slides.length;
      setNumberOfSlides(slidesCount);
      setIsStart(instance.isBeginning);
      setIsEnd(instance.isEnd);
      setActiveSlideIndex(instance.realIndex);
      onSlideChange?.({
        start: instance.isBeginning,
        end: instance.isEnd,
        active: instance.realIndex,
      });
    }
  };

  SwiperCore.use([Pagination, Navigation]);

  return (
    <ThemeProvider theme={theme}>
      <CarouselWrapper>
        <Swiper
          onSwiper={(swiper: SwiperCore) => {
            setSwiperInstance(swiper);
            updateElements(swiper);
          }}
          onSlideChangeTransitionStart={(swiper: SwiperCore) => {
            updateElements(swiper);
          }}
          breakpoints={breakpoints}
          modules={[Keyboard, A11y]}
          a11y={{ enabled: true }}
          keyboard={{ enabled: keyboard, pageUpDown: true }}
          centeredSlides={centeredSlides}
          // setting loop to true introduces bug (swiper-react), as of 5 April 2023
          loop={false}
          preloadImages={false} // enables images to load
          spaceBetween={spaceBetween}
          watchSlidesProgress
          touchStartPreventDefault={false}
          pagination={
            paginationType === 'numbered'
              ? {
                  el: '.swiper-pagination',
                  type: 'fraction',
                  formatFractionCurrent(number: number) {
                    return `${number}`;
                  },
                }
              : false
          }
          navigation={
            paginationType === 'numbered'
              ? {
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                }
              : false
          }
          watchOverflow
        >
          {children}

          {pagination &&
            numberOfSlides > 1 &&
            paginationType !== 'numbered' && (
              <DotsWrapper
                activeDotIndex={activeSlideIndex}
                numberOfDots={numberOfSlides}
                onDotClick={(index: number) => {
                  if (setSlideToChange) setSlideToChange(index + 1);
                  else goToSlide(index);
                }}
              />
            )}

          {paginationType === 'numbered' && (
            <CarouselCustomPagination>
              <div className="swiper-button-prev">
                <Icon size="12px" name="DXPFeatherChevronLeft" />
              </div>
              <div className="swiper-pagination" />
              <div className="swiper-button-next">
                <Icon size="12px" name="DXPFeatherChevronRight" />
              </div>
            </CarouselCustomPagination>
          )}
        </Swiper>
        {arrows && (
          <CarouselArrows
            isStart={!loop && isStart}
            isEnd={!loop && isEnd}
            onLeftButtonClick={() => swiperInstance?.slidePrev()}
            onRightButtonClick={() => swiperInstance?.slideNext()}
            className="full-width-arrows"
          />
        )}
      </CarouselWrapper>
    </ThemeProvider>
  );
};

export default Carousel;
