import Carousel from '../Carousel/Carousel';
import ComponentLayout from '../Layout/ComponentLayout';
import { IDecorativeCarousel } from '../DecorativeCarousel/DecorativeCarousel.props';
import { theme } from '@weirgroup/weir-dls';
import { FC } from 'react';
import { ThemeProvider } from 'styled-components';
import { SwiperSlide } from 'swiper/react';
import useDeviceResize from '../../../services/Optimizely/hooks/useDeviceResize';
import Image from '../Image/Image';
import ImageWrapper from './DecorativeCarousel.styles';

const DecorativeCarousel: FC<IDecorativeCarousel> = ({
  images,
  numberPagination,
}: IDecorativeCarousel) => {
  const { isDesktop } = useDeviceResize();

  console.log('images=', images);

  return (
    <ComponentLayout noPadding>
      <ThemeProvider theme={theme}>
        <Carousel
          pagination={!isDesktop}
          arrows={isDesktop}
          centeredSlides
          loop
          paginationType={!isDesktop && numberPagination && 'numbered'}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            768: {
              slidesPerView: 1.4,
              spaceBetween: 145,
            },
          }}
        >
          {images?.map(({ imageUrl, altText, title }) => (
            <SwiperSlide key={altText}>
              <ImageWrapper>
                <Image imageUrl={imageUrl} altText={altText} title={title} />
              </ImageWrapper>
            </SwiperSlide>
          ))}
        </Carousel>
      </ThemeProvider>
    </ComponentLayout>
  );
};

export default DecorativeCarousel;
