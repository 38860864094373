import { ReactElement, memo } from 'react';
import { MiniCard } from '@weirgroup/weir-dls';
import { LinkBlockProps } from './HelpfulLinksBlock.props';

const LinkBlock = ({ content, mode }: LinkBlockProps): ReactElement => {
  return (
    <>
      <div data-testid="media_Card" className="link" key={content.link?.text}>
        <MiniCard
          division="Esco"
          description={content.description}
          title={content.link?.text}
          lastUsed=""
          imgSrc={`/esco-resource-center/img/${content.icon}.png`}
          onClick={() => {
            window.open(content.link?.href, content.link?.target);
          }}
        />
      </div>
    </>
  );
};

export default memo(LinkBlock);
