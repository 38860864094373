const size = {
  breakpointS: '320rem',
  breakpointM: '48rem',
  breakpointL: '62rem',
  breakpointXL: '85.3125rem',
  breakpointXXL: '105rem',
};

export const breakpoints = {
  s: `(min-width: ${size.breakpointS})`,
  m: `(min-width: ${size.breakpointM})`,
  l: `(min-width: ${size.breakpointL})`,
  xl: `(min-width: ${size.breakpointXL})`,
  xxl: `(min-width: ${size.breakpointXXL})`,
};
