import { Button } from '@weirgroup/weir-dls';
import styled from 'styled-components';

const StyledPrimaryButtonWrapper = styled.span`
  button:not([disabled]):not(:hover),
  a:not(:hover) {
    background: linear-gradient(135deg, #002e5d 1.13%, #005eb8 100.75%);
  }
`;

const StyledPrimaryButton = (props: any) => (
  <StyledPrimaryButtonWrapper>
    <Button {...props} />
  </StyledPrimaryButtonWrapper>
);

export default StyledPrimaryButton;
