import { ReactElement, memo } from 'react';

import { addEditAttributes } from '../../../services/Optimizely/utils/episerverAttributes';
import TextAreaBlockContentProps from './TextAreaBlock.props';

const TextAreaBlock = ({
  content,
  attribute,
  mode,
}: TextAreaBlockContentProps): ReactElement => {
  return (
    <div
      {...addEditAttributes(attribute)}
      dangerouslySetInnerHTML={{
        __html: content.text?.html || '',
      }}
    />
  );
};

export default memo(TextAreaBlock);
